// @ts-nocheck

import React, { FC, useEffect, useState } from "react";
import { Logo } from "../../components/logo";
import { AuthForm } from "../../components/auth-form";
import { PageWrapper, Header } from "./styled";
import { useAuth } from "../../controllers/auth-controller";
import { useSignUpService } from "../../services/signup";
import { useSignInService } from "../../services/signin";
import { useNavigate } from "react-router-dom";

export const LoginPage: FC<{}> = () => {
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  let navigate = useNavigate();
  let auth = useAuth();
  const [isSignUpInProgress, signUpResponse, signUp] = useSignUpService();
  const [isSignInInProgress, signInResponse, signIn] = useSignInService();


  useEffect (() => {
    if (auth && auth.token && auth.user) {
      navigate("/workspaces", {replace: true});
    }

  });


  const onSignUp = () => {
    setShowSplashScreen(true);
  };

  const hideSplashScreen = () => {
    setShowSplashScreen(false);
  };

  const onSignIn = () => {
    navigate("/workspaces", {replace: true});
  };

  return (
    <>
      <PageWrapper>
        <Header>
          <Logo />
        </Header>

        <AuthForm
          isSignInInProgress={isSignInInProgress}
          signIn={signIn}
          isSignUpInProgress={isSignUpInProgress}
          signUp={signUp}
          onSign={onSignIn}
          onSignUp={onSignUp}
          showSplashScreen={showSplashScreen}
          setShowSplashScreen={hideSplashScreen}
        />
      </PageWrapper>
    </>
  );
};
