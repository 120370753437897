
import { useQuery, gql } from "@apollo/client";
import { Heading, Container } from "./styled";
import { Collapse, Spin } from 'antd';
import { logout } from "../../helpers/util";
import { TenantAppsCard } from "../../components/tenant-apps";
import { JiraCloudUrls } from '../../components/jira-cloud-urls';
import "./index.css";
import { useState } from "react";
const { Panel } = Collapse;
export const Settings = (props: any) => {

  const GET_SETTINGS = gql`
  query getAllSettings($after: String,$before: String) {
    getTenant {
      result {
        ... on Tenant {
          jiraCloudURLs
        } 
      }       
      success
      errors {
        errorCode
        errorMessage
      }
    }
    getTenantApps(first:6, after: $after,before: $before){
      pageInfo{
        prevPageCursor
        nextPageCursor
      }
      nodes{
        id
        ... on TenantApp{
          id
          name
          description
          clientId
          scopes
          clientSecret
          createdDate
        }
      }
    }
  }
 `;
  const [afterPage, setAfterPage] = useState<any>(null)
  const [beforePage, setBeforePage] = useState<any>(null)


  const { loading, error, data, refetch } = useQuery(GET_SETTINGS, {
    variables: { after: afterPage, before: beforePage },
  });

  const handleLogout = () => {
    logout();
  }

  if (loading)
    return (
      <div className="center-loader">
        <Spin size="large" />
      </div>
    );
  if (error)
    return (
      <p>
        Your session has expired, please <b onClick={() => handleLogout()}>login</b>{" "}
        again
      </p>
    );

  return (
    <>
      <Container>
        <Heading>Settings</Heading>
      </Container>
      <br />
      <br />
      <div className='settings-cards'>
        <Collapse defaultActiveKey={['1']} >
          <Panel header="Jira Cloud Urls" key='1'>
            <JiraCloudUrls data={data} />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={['2']}>
          <Panel header="Tenant Apps" key='2'>
            <TenantAppsCard datatenantApps={data} setAfterPage={setAfterPage} setBeforePage={setBeforePage} refetch={refetch} />
          </Panel>
        </Collapse>
      </div>
    </>
  );
}