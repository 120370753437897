
import { gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import { NoLinks } from "../jira-cloud-urls/styled";
import {
  Form,
  Drawer,
  Col,
  Row,
  Table,
  notification,
  Input,
  Button,
} from "antd";
import _ from "lodash";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Container } from "../../pages/settings-page/styled";
import { AddNewLink } from "../jira-cloud-urls/styled";

export interface TenantApp {
  appId: string;
  name: string;
  description: string;
  clientId: string;
  scopes: string;
  clientSecret: string;
}

export const TenantAppsCard = (props: any) => {

  //   const DELETE_TENANT_APP = gql`
  // 	mutation deleteTaskExecutors(
  // 		$ids: [String!]!
  // 	) {
  // 		deleteTaskExecutors(
  // 			ids: $ids
  // 		) {
  // 			success
  // 			errors {
  // 				errorCode
  // 				errorMessage
  // 			}
  // 		}
  // 	}
  // `;
  // const [delApp, { error: deleteError, data: deleteData, loading: deleteLoading }] = useMutation(DELETE_TENANT_APP);
  // const deleteTenantApp = (row: any) => {
  //   Modal.confirm({
  //     title: 'Delete the app?',
  //     content: "Delete this app? This action is not reversible.",
  //     okText: 'Delete',
  //     onOk() {
  //       delApp({
  //         variables: {
  //           ids: [_.get(row, 'id')]
  //         }
  //       }).then((res) => {
  //         if (_.get(res, 'data.delTex.errors') == null) {
  //           notification["success"]({
  //             description: "Workflow deleted successfully",
  //             message: "Success",
  //           });
  //           refetch();
  //         } else if (_.get(res, 'data.delTex.errors[0].errorMessage')) {
  //           notification.error({
  //             message: 'Error',
  //             description: _.get(res, 'data.delTex.errors[0].errorMessage'),
  //           });
  //         }
  //       })
  //         .catch((err) => {
  //           notification["error"]({
  //             message: "An unknown error occured",
  //             description: err?.message,
  //           });
  //         });
  //     },
  //     cancelButtonProps: {
  //       disabled: false
  //     },
  //     // confirmLoading:true,
  //     cancelText: 'Cancel'
  //   })
  // }

  const tenantApps = props.datatenantApps?.getTenantApps?.nodes?.map((item: any) => {
    return {
      name: item?.name,
      description: item?.description,
      clientId: item?.clientId,
      clientSecret: item?.clientSecret,
      id: item?.id,
      scopes: item?.scopes,
    };
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Client Id",
      dataIndex: "clientId",
    },
    // {
    //   title: "Scopes",
    //   dataIndex: "scopes",
    // },
    //   {
    //     title: "Delete",
    //     dataIndex: "id",
    //     render: (text:any, row:any) => (
    //         <Space size="middle">
    //             <div><DeleteOutlined onClick={()=>deleteTenantApp(row)} /></div>
    //         </Space>
    //     )
    // }
  ];
  const onChangeAfterBeforePage = (type: any) => {

    console.log(_.get(props.datatenantApps, 'getTenantApps.pageInfo'))

    if (_.get(props.datatenantApps, 'getTenantApps.pageInfo')) {
      if (type === 'next' && _.get(props.datatenantApps, 'getTenantApps.pageInfo.nextPageCursor')) {
        props.setBeforePage(null)
        props.setAfterPage(_.get(props.datatenantApps, 'getTenantApps.pageInfo.nextPageCursor'))
      } else if (type === 'prev' && _.get(props.datatenantApps, 'getTenantApps.pageInfo.prevPageCursor')) {
        props.setAfterPage(null)
        props.setBeforePage(_.get(props.datatenantApps, 'getTenantApps.pageInfo.prevPageCursor'))
      }
    }
  }

  const dataforTable: any[] = [];

  for (let i = 0; i < tenantApps?.length; i++) {
    dataforTable.push({
      key: i,
      name: tenantApps[i].name,
      description: tenantApps[i].description,
      clientId: tenantApps[i].clientId,
      clientSecret: tenantApps[i].clientSecret,
      scopes: tenantApps[i].scopes,
      id: tenantApps[i].id,
    });
  }

  return (
    <>
      {
        !_.isEmpty(dataforTable) ?
          (
            <Table
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              columns={columns}
              dataSource={dataforTable}
            />
          ) :
          <div>
            <NoLinks>No Links to show </NoLinks>
            <br />
          </div>
      }
      <Container>
        <CreateTenantAppForm refetch={props.refetch} />,
      </Container>
      <br />
      {_.isArray(tenantApps) === true && _.size(tenantApps) > 0 && (_.get(props.datatenantApps, 'getTenantApps.pageInfo.prevPageCursor') || _.get(props.datatenantApps, 'getTenantApps.pageInfo.nextPageCursor')) && <div className="row" style={{ textAlign: 'center' }}>
        <Button icon={<LeftOutlined style={{ marginLeft: '3px' }} />} disabled={_.get(props.datatenantApps, 'getTenantApps.pageInfo.prevPageCursor') ? false : true} style={{ marginRight: "25px" }} onClick={() => onChangeAfterBeforePage('prev')}>Previous</Button>
        <Button disabled={_.get(props.datatenantApps, 'getTenantApps.pageInfo.nextPageCursor') ? false : true} onClick={() => onChangeAfterBeforePage('next')}>
          Next <RightOutlined style={{ marginLeft: '3px' }} /></Button>
      </div>}
    </>
  );
};

type DrawerState = "visible" | "hidden";

// @ts-ignore
export const CreateTenantAppForm = (props) => {
  const CREATE_TENANT_APP = gql`
    mutation CreateTenantApp($name: String!, $description: String) {
        createTenantApp(name: $name, description: $description) {
        success
        result {
          ... on TenantApp {
            id
          }
        }
        errors {
          errorCode
          errorMessage
        }
      }
    }
  `;


  const [form] = Form.useForm();

  const [createTenantAppGQL] = useMutation(CREATE_TENANT_APP);

  const [drawerState, setDrawerState] = useState<DrawerState>("hidden");
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const onClose = () => {
    setDrawerState("hidden");
  };

  const onFinish = () => {
    setLoadingButton(true);
    createTenantAppGQL({
      variables: {
        name: form.getFieldValue("name"),
        description: form.getFieldValue("description"),
      },
    })
      .then(() => {
        notification["success"]({
          message: "New TenantApp created",
          description: "Your New TenantApp is Ready!",
        });
        setLoadingButton(false);
        form.resetFields();
        onClose();
        props.refetch();
      })
      .catch(() => {
        notification["error"]({
          message: "Some Error Occured",
          description: "Some error occured while creating new TenantApp!",
        });
        form.resetFields();
        setLoadingButton(false);
      });
  };

  const showDrawer = () => {
    setDrawerState("visible");
  };

  // const toggleDrawer = () => {
  //   switch (drawerState) {
  //     case "visible":
  //       setDrawerState("hidden");
  //       break;
  //     case "hidden":
  //       setDrawerState("visible");
  //       break;
  //   }
  // };

  return (
    <>
      <AddNewLink onClick={showDrawer}><span>&#43;</span> New tenant app</AddNewLink>
      <Drawer
        title="Create a new TenantApp"
        width={720}
        onClose={onClose}
        visible={drawerState === "visible"}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={onFinish} type="primary" loading={loadingButton}>
              Create
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "TenantApp name" }]}
              >
                <Input placeholder="Please enter TenantApp name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "please enter a description for this TenantApp",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="please enter description"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};