// @ts-nocheck

import { createContext, useContext, useState } from "react";

const useToken = () =>  {
  
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    return user;
  };
  
  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());
  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const saveUser = user => {
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
  };

  const [authType, setAuthType] = useState();
  const saveAuthType = authType => {
    setAuthType(authType);
  };
  
  return [
    token,
    saveToken,
    saveUser,
    user,
    authType,
    saveAuthType
  ]
}

const authContext = createContext(null);

export const useAuth = () => {
  return useContext(authContext);
};

export const AuthController = ({ children }) => {
  const [token, saveToken , saveUser , user, authType, saveAuthType ] = useToken();
  
  const setSignedInToken = (token, cb) => {
    saveToken(token);
    cb && cb();
  };

  //TODO: use this process to set the signed in user and use it in the profile
  const setSignedInUser= (user, cb) => {
    saveUser(user);
    cb && cb();
  }

  const getSignedInUser = () => {
    return JSON.parse(localStorage.getItem('user'))
  }

  const setAuthType = (authType) => {
    saveAuthType(authType);
  }
  const auth = {
    token,
    setSignedInToken,
    setSignedInUser,
    getSignedInUser,
    user,
    authType,
    setAuthType
  };

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
