
import { Col, Collapse, Input, Row, Select } from 'antd';
import { InfoCircleOutlined} from '@ant-design/icons';
import DeleteButton from './delete-button';

const RunDockerTask = (props:any) => {
    const { item, handleChange, handleDependsOnSelectChange, deleteTask} = props;
    const { Panel } = Collapse;
    const { Option } = Select;
    const handleMultiChange = (value:string | string[], field : string) => {
        handleChange(value , field);
    }
    console.log('inside the run docker task component, item:' + item)
    return (
        <Panel
            {...props}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>Name</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="docker task name"  value={item.name} onChange={(ev:any) => handleChange(ev.target.value,'name')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>Docker Image</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="docker image"  value={item.dockerImage} onChange={(ev:any) => handleChange(ev.target.value,'dockerImage')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className='gutter-row' span={6}>
                        <span style={{marginRight: '6px'}}>Commands</span>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className='gutter-row' span={10}>
                    <Select mode="tags"
                        placeholder="Please enter multiple commands"
                        style={{ width: '100%' }}
                        defaultValue={item.commands}
                        onChange={(ev:any) => handleMultiChange(ev,'commands')}
                    />
                    </Col>
                </Row>

                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className='gutter-row' span={6}>
                        <span style={{marginRight: '6px'}}>Arguments</span>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className='gutter-row' span={10}>
                    <Select mode="tags"
                        placeholder="Please enter multiple arguments"
                        style={{ width: '100%' }}
                        defaultValue={item.args}
                        onChange={(ev:any) => handleMultiChange(ev,'args')}
                    />
                    </Col>
                </Row>
                {(props.definitionData.filter((data:any) => data.id !== item.id && data.name.length > 0)).length > 0 && (
                    <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                        <Col className='gutter-row' span={6}>
                            <span style={{marginRight: '6px'}}>Depends On</span>
                            <InfoCircleOutlined />
                        </Col>
                        <Col className='gutter-row' span={10}>
                            <Select
                                mode="multiple"
                                placeholder="Please select multiple"
                                onChange={handleDependsOnSelectChange}
                                value={item.dependsOn}
                                style={{ width: '100%' }}  >
                                {props.definitionData.filter((data:any) => data.id !== item.id).map((value:any) => <Option key={value.id}>{value.name}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                )}
        <br/>
        <br/>
        <DeleteButton deleteTask={deleteTask} />
      </Panel>
    )
}

export default RunDockerTask;
