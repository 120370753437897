const SuccessIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="8" cy="7.66699" r="7" fill="#0C9E84" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.9914 5.12392C12.1567 5.28914 12.1567 5.55701 11.9914 5.72223L7.33761 10.3761C7.17239 10.5413 6.90451 10.5413 6.73929 10.3761L4.62392 8.26068C4.45869 8.09546 4.45869 7.82759 4.62392 7.66236C4.78914 7.49714 5.05701 7.49714 5.22223 7.66236L7.03845 9.47858L11.3931 5.12392C11.5583 4.95869 11.8262 4.95869 11.9914 5.12392Z"
			fill="white"
		/>
	</svg>
);

export default SuccessIcon;
