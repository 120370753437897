export function execTypeMap(status: any) {
	switch (status) {
		case "WFEXECTYPE_ON_DEMAND":
			return "On Demand";
		case "WFEXECTYPE_SCHEDULED":
			return "Scheduled";
		case "WFEXECTYPE_WF_TRIGGERED":
			return "Triggered";
		default:
			return "Error";
	}
}
