import { FC } from "react";
import { Link } from "react-router-dom";

import { Space, Card, Button, Empty, Dropdown, Menu } from "antd";
import _ from "lodash";
import { Workspace } from "./index";
import { DescriptionWrapper, LinksWrapper, LinkWrapper } from "./styled";
import { MoreOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import "./index.css";

const WorkspaceCard: FC<{
    workspaceId: string;
    name: string;
    description: string;
    numWorkflows: number;
    numTex: number;
    deleteWS: any;
    openWSSettings : any;
}> = (props): JSX.Element => {
    const { name, description, numWorkflows, numTex, workspaceId, deleteWS, openWSSettings } = props;

    const workspaceOptions = (
        <Menu>
          <Menu.Item key={1}>
            <Button onClick={() => deleteWS(workspaceId)} type="text"> Delete </Button>
          </Menu.Item>
          <Menu.Item key={2}>
            <Button onClick={() => openWSSettings(workspaceId)} type="text" >Settings</Button>
          </Menu.Item>
        </Menu>
    );

    return (
        <Card title={name} className="worspace_card"
            extra={[
                <Dropdown overlay={workspaceOptions} key={1} >
                    <MoreOutlined key="delete" />
                </Dropdown>
            ]}>
            <DescriptionWrapper>{description}</DescriptionWrapper>
            <LinksWrapper>
                <LinkWrapper>
                    <Link
                        to={`/workflows?workspaceId=${workspaceId}`}
                        style={{ paddingRight: 20 }}
                    >
                        <h2>{numWorkflows}</h2> workflows
                    </Link>
                </LinkWrapper>
                <LinkWrapper>
                    <Link to={`/taskexecutors?workspaceId=${workspaceId}`}>
                        <h2>{numTex}</h2> Task Executors
                    </Link>
                </LinkWrapper>
            </LinksWrapper>
        </Card>
    );
};

export const WorkspaceCards: FC<{
    workspaces: Workspace[],
    onChangeAfterBeforePage: any,
    data_details: any,
    deleteWS: any,
    openWSSettings : any
}> = (props) => {
    const { workspaces, data_details, deleteWS, openWSSettings } = props;

    return (
        <>
            <h3 className="my-workspace">My Workspaces</h3>
            {
                !_.isEmpty(workspaces) ?
                    (
                        <Space direction="horizontal" size={[8, 16]} wrap>
                            {workspaces.map((workspace, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <WorkspaceCard
                                    key={workspace.workspaceId}
                                    workspaceId={workspace.workspaceId}
                                    name={workspace.name}
                                    description={workspace.description}
                                    numWorkflows={workspace.numberOfWorkflows}
                                    numTex={workspace.numberOfTaskExecutors}
                                    deleteWS={deleteWS}
                                    openWSSettings={openWSSettings}
                                />
                            ))}
                        </Space>
                    ) :
                    (<Empty />)
            }
            <br />
            <br />
            {_.isArray(workspaces) == true && _.size(workspaces) > 0 && (_.get(data_details, 'getWorkspaces.pageInfo.prevPageCursor') || _.get(data_details, 'getWorkspaces.pageInfo.nextPageCursor')) && <div className="row" style={{ textAlign: 'center' }}>
                <Button icon={<LeftOutlined style={{ marginLeft: '3px' }} />} disabled={_.get(data_details, 'getWorkspaces.pageInfo.prevPageCursor') ? false : true} style={{ marginRight: "25px" }} onClick={() => props.onChangeAfterBeforePage('prev')}>Previous</Button>
                <Button disabled={_.get(data_details, 'getWorkspaces.pageInfo.nextPageCursor') ? false : true} onClick={() => props.onChangeAfterBeforePage('next')}>
                    Next <RightOutlined style={{ marginLeft: '3px' }} /></Button>
            </div>}
        </>
    );
};