import { useState } from "react";
import { config } from "../../conf";
// @ts-ignore
const SIGNUP_URL = config.api_url.concat("/api/v1/signup");

export const useSignUpService = () => {
  const [isSignUpInProgress, setSignUpInProgress] = useState(false);
  const [signUpResponse, setSignUpResponse] = useState();

  // @ts-ignore
  const signUp = async ({ companyName, emailAddress, name, password }, cb) => {
    setSignUpInProgress(true);
    const response = await fetch(SIGNUP_URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ companyName, emailAddress, name, password }),
    });
    const jsonResponse = await response.json();
    setSignUpInProgress(false);
    setSignUpResponse(jsonResponse);
    cb();
  };

  return [isSignUpInProgress, signUpResponse, signUp];
};
