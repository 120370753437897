import styled from "@emotion/styled";


export const NoLinks = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #616775;
`;

export const AddNewLink = styled.button`
	box-sizing: border-box;
	background: #FBFCFD;
	border: 1px solid #252CE1;
	border-radius: 2px;
	color: #252CE1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 7px 14px;
	cursor: pointer;
	margin-top: 20px;
`;

export const LinkButton = styled.button`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
color: #383B40;
border: none;
cursor: pointer;
background: transparent;
&:hover {
   text-decoration: underline; 
  }
`;
