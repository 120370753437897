import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { Heading } from "./styled";
import './index.css';

export const UserProfile = (props:any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" id="toggleModal" onClick={showModal}>
      </Button>
      <Modal 
        width={300}
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
          ]}>
            <p className="profile">Profile</p>
            <Heading>
            {props?.user?.name}
            </Heading>
            <h3 className="sub-heading">{props?.user?.email}</h3>
            
      </Modal>
    </>
  );
};
