const InProgessIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.13731 2.03204H8.86741C9.2428 2.03204 9.54993 1.7249 9.54993 1.34952C9.54993 0.974128 9.2428 0.666992 8.86741 0.666992H6.13731C5.76192 0.666992 5.45479 0.974128 5.45479 1.34952C5.45479 1.7249 5.76192 2.03204 6.13731 2.03204ZM12.3005 5.02832L12.8124 4.51643C12.9386 4.38945 13.0094 4.21769 13.0094 4.03866C13.0094 3.85963 12.9386 3.68788 12.8124 3.56089L12.8056 3.55407C12.6786 3.42787 12.5068 3.35703 12.3278 3.35703C12.1488 3.35703 11.977 3.42787 11.85 3.55407L11.3381 4.06596C10.2511 3.19119 8.89767 2.71435 7.50236 2.71456C4.22624 2.71456 1.44155 5.41736 1.35964 8.69348C1.33757 9.51388 1.48016 10.3304 1.77898 11.0947C2.0778 11.8591 2.52679 12.5558 3.09942 13.1437C3.67206 13.7316 4.35672 14.1988 5.11296 14.5176C5.86919 14.8365 6.68166 15.0005 7.50236 15C8.65877 15.0006 9.79183 14.6746 10.7711 14.0595C11.7503 13.4443 12.5358 12.5651 13.0372 11.523C13.5387 10.481 13.7355 9.31851 13.6052 8.16947C13.4748 7.02043 13.0226 5.93158 12.3005 5.02832ZM8.18489 8.85728C8.18489 9.23267 7.87775 9.53981 7.50236 9.53981C7.12697 9.53981 6.81984 9.23267 6.81984 8.85728V6.12719C6.81984 5.7518 7.12697 5.44466 7.50236 5.44466C7.87775 5.44466 8.18489 5.7518 8.18489 6.12719V8.85728Z"
			fill="#EFB41C"
		/>
	</svg>
);

export default InProgessIcon;
