import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Breadcrumb, Button, Modal, notification, Spin, Table } from "antd";
import _ from "lodash";
import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { logout } from "../../helpers/util";
import { LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';
import { CreateRemotesForm } from "../../components/create-remotes";

const GET_REMOTES = gql`
query GetRemoteSystems($after: String, $before: String, $workspaceId: String) {
    getRemoteSystems( workspaceId:$workspaceId, after: $after, before: $before, first: 30  ){
        pageInfo {
            prevPageCursor
            nextPageCursor
        }
        nodes {
            ... on LDAPRemoteSystem {
                id
                name
                workspaceId
            }
        }
    }
}
`;

const DELETE_REMOTE = gql`
	mutation deleteRemoteSystem(
		$ids: [String!]!
	) {
		deleteRemoteSystem(
			ids: $ids
		) {
			success
			errors {
				errorCode
				errorMessage
			}
		}
	}
`;

type DrawerState = "visible" | "hidden";
export const WorkspaceSettingsPage: FC<{}> = () => {

  const [afterPage, setAfterPage] = useState<any>(null);
  const [beforePage, setBeforePage] = useState<any>(null);
  const [drawer, setDrawer] = useState<DrawerState>("hidden");
  const [remoteIdToUpdate, setRemoteIdToUpdate] = useState<string | null>(null);

  const params = useParams();
  const { workspaceId }: any = params;

  const handleLogout = () => {
    logout();
  }

  const { loading, error, data, refetch } = useQuery(GET_REMOTES, {
    variables: { after: afterPage, before: beforePage, workspaceId: workspaceId },
  });

  const [delRemote] = useMutation(DELETE_REMOTE);


  const deleteRemote = (id: string) => {
    Modal.confirm({
      title: 'Delete Remote System?',
      content: "Delete this remote system? \n This action is not reversible.",
      okText: 'Delete',
      onOk() {
        delRemote({
          variables: {
            ids: [id]
          }
        }).then((res) => {

          if (_.get(res, 'deleteData.deleteRemoteSystem.errors') == null) {
            notification["success"]({
              description: "Remote System deleted successfully",
              message: "Success",
            });

            refetch();
          } else if (_.get(res, 'deleteData.deleteRemoteSystem.errors[0].errorMessage')) {
            notification.error({
              message: 'Error',
              description: _.get(res, 'deleteData.deleteRemoteSystem.errors[0].errorMessage'),
            });
          }

        })
          .catch((err) => {
            notification["error"]({
              message: "An unknown error occured",
              description: err?.message,
            });
          });
      },
      cancelButtonProps: {
        disabled: false
      },
      cancelText: 'Cancel'
    })
  }
  const onChangeAfterBeforePage = (type: any) => {
    if (_.get(data, 'getRemoteSystems.pageInfo')) {
      if (type === 'next' && _.get(data, 'getRemoteSystems.pageInfo.nextPageCursor')) {
        setBeforePage(null)
        setAfterPage(_.get(data, 'getRemoteSystems.pageInfo.nextPageCursor'))
      } else if (type === 'prev' && _.get(data, 'getRemoteSystems.pageInfo.prevPageCursor')) {
        setAfterPage(null)
        setBeforePage(_.get(data, 'getRemoteSystems.pageInfo.prevPageCursor'))
      }
    }
  }

  const updateRemote = (id: string)  => {
    //open the update form
    setDrawer("visible")
    //set the id of remote to be populated in the form
    setRemoteIdToUpdate(id);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (placholder: any, row: any) => (
        <LinkButton type="text" onClick={() => updateRemote(row.id)} >View Details</LinkButton>
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      render: (placholder: any, row: any) => (
        <div><DeleteOutlined onClick={() => deleteRemote(row.id)} /></div>
      )
    }
  ];

  const remotes = data?.getRemoteSystems?.nodes.map((remote: any) => {
    return {
      key: remote.id,
      name: remote.name,
      type: remote.__typename,
      workspaceId: remote.workspaceId,
      id: remote.id
    };
  })
  if (loading)
    return (
      <div className="center-loader">
        <Spin size="large" />
      </div>
    );
  if (error)
    return (
      <p>
        Your session has expired, please <b onClick={() => handleLogout()}>login</b>{" "}
        again
      </p>
    );

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/workspaces">Workspaces</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
      </Breadcrumb>

      <Container>
        <Heading>Workspace Settings</Heading>

        <CreateRemotesForm workspaceId={workspaceId} drawer={drawer} setDrawer={setDrawer} refetch={refetch} remoteId={remoteIdToUpdate} setRemoteIdToUpdate={setRemoteIdToUpdate} />
      </Container>
      <br />
      <br />


        {
          (remotes?.length > 0) ?
            (
              <Table
                pagination={false}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                columns={columns}
                dataSource={remotes}
              />
            ) :
            <div>
              <NoData>No Data</NoData>
              <br />
            </div>
        }
        <br />
        {_.isArray(remotes) === true && _.size(remotes) > 0 && 
        (_.get(data, 'getRemoteSystems.pageInfo.prevPageCursor') || _.get(data, 'getRemoteSystems.pageInfo.nextPageCursor')) && 
        <div className="row" style={{ textAlign: 'center' }}>
          <Button disabled={_.get(data, 'getRemoteSystems.pageInfo.prevPageCursor') ? false : true} onClick={() => onChangeAfterBeforePage('prev')}>
            <LeftOutlined style={{ marginLeft: '3px' }} /> Previous
          </Button>
          <Button disabled={_.get(data, 'getRemoteSystems.pageInfo.nextPageCursor') ? false : true} onClick={() => onChangeAfterBeforePage('next')}>
            Next <RightOutlined style={{ marginLeft: '3px' }} />
          </Button>
        </div>}
    </>
  );
};

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Heading = styled.h1`
	font-size: 28px;
	font-weight: bold;
	text-transform: capitalize;
`;

export const NoData = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #616775;
`;


export const LinkButton = styled(Button)`
background : transparent;
color: #2F80ED;
`