import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import InputDrag from "../../components/create-workflow/input-drag";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
	Spin,
	Input,
	Select,
} from "antd";
import { CaretDownOutlined, CloseOutlined,DeleteOutlined,MoreOutlined } from '@ant-design/icons';

import History from "./history";

import {
	Heading,
	Container,
	
} from "./styled";
import "./index.css";
import { logout } from "../../helpers/util";
import _ from "lodash";


const GET_WORKFLOW_BY_ID = gql`
	query($id: String!) {
		getWorkflowById(id: $id) {
			id
			name
			
		}
	}
`;

export const WorkflowsHistory: FC<{}> = () => {
	const myRef = useRef(null)
const params = useParams();
	const { workflowsId }: any = params;
	const { loading, error, data: firstData, refetch } = useQuery(
		GET_WORKFLOW_BY_ID,
		{
			variables: { id: workflowsId },
			fetchPolicy: "network-only",
		}
	);
	const handleLogOut = () => {
		logout();
	};

	if (loading)
		return (
			<div className="center-loader">
				<Spin size="large" />
			</div>
		);
	if (error)
		return (
			<p>
				Your session has expired, please{" "}
				<b onClick={handleLogOut}>login</b> again
			</p>
		);

	


	const { getWorkflowById }: any = firstData;


	// @ts-ignore
	const MenuItem = () => {
			return <History />;
	
	};

	return (
		<>
			<Container >
				<Heading ref={myRef}>{getWorkflowById?.name}</Heading>
			</Container>
			
			<MenuItem></MenuItem>
		</>
	);
};
