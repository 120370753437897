import React from "react";

const Logo = () => (
  <svg
    width="32"
    height="30"
    viewBox="0 0 643 601"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.4126 257.195C80.7035 264.119 76.6355 271.491 69.3263 273.661C60.3273 276.333 50.6703 280.05 42.7002 284.399C34.2574 289.007 29.7318 293.237 28.2203 296.104C27.6529 297.18 27.7248 297.644 27.7693 297.871C27.8367 298.214 28.2138 299.568 30.5429 301.87C35.5715 306.839 47.0133 313.586 69.6814 320.958C76.9261 323.314 80.7831 330.787 78.296 337.65C75.809 344.513 67.9199 348.166 60.6751 345.811C36.8815 338.073 20.2526 329.671 10.5199 320.054C5.46836 315.063 1.79222 309.266 0.498373 302.674C-0.818253 295.967 0.584571 289.712 3.40503 284.363C8.66913 274.379 19.1468 266.929 28.8532 261.631C39.0322 256.076 50.6701 251.664 61.0302 248.588C68.3393 246.417 76.1217 250.271 78.4126 257.195Z"
      fill="url(#paint0_linear_1144_23329)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M563.744 257.195C561.453 264.119 565.521 271.491 572.83 273.661C581.829 276.333 591.486 280.05 599.456 284.399C607.899 289.007 612.424 293.237 613.936 296.104C614.503 297.18 614.431 297.644 614.387 297.871C614.32 298.214 613.942 299.568 611.613 301.87C606.585 306.839 595.143 313.586 572.475 320.958C565.23 323.314 561.373 330.787 563.86 337.65C566.347 344.513 574.236 348.166 581.481 345.811C605.275 338.073 621.904 329.671 631.636 320.054C636.688 315.063 640.364 309.266 641.658 302.674C642.975 295.967 641.572 289.712 638.751 284.363C633.487 274.379 623.009 266.929 613.303 261.631C603.124 256.076 591.486 251.664 581.126 248.588C573.817 246.417 566.035 250.271 563.744 257.195Z"
      fill="url(#paint1_linear_1144_23329)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.333 55.8453C277.184 77.0324 269.503 103.737 265.595 121.896C264.056 129.046 256.751 133.648 249.277 132.176C241.803 130.704 236.992 123.715 238.531 116.566C242.682 97.2779 250.889 68.4731 262.213 44.8338C267.83 33.1064 274.64 21.7367 282.765 13.4693C290.783 5.31221 302.389 -1.99532 316.706 0.494853C343.824 5.21147 363.819 31.4026 377.418 70.2188C391.429 110.214 400.568 168.707 402.309 247.815L402.837 271.806L381.358 259.409C330.193 229.878 252.876 190.195 187.026 166.811C153.858 155.033 124.962 147.895 104.2 147.562C93.8362 147.396 86.9265 148.965 82.6934 151.27C79.1012 153.226 76.6899 156.062 75.6784 161.288L75.5809 161.791L75.4429 162.286C75.0026 163.866 75.196 168.316 79.0307 176.444C82.5907 183.99 88.2701 192.581 94.9647 201.152C101.606 209.655 108.945 217.757 115.519 224.291C122.308 231.038 127.613 235.445 130.202 237.096C136.551 241.145 138.266 249.351 134.034 255.424C129.801 261.498 121.223 263.139 114.875 259.09C109.692 255.785 102.693 249.66 95.6111 242.622C88.3158 235.372 80.2195 226.436 72.8287 216.973C65.4915 207.579 58.5422 197.274 53.8451 187.318C49.4949 178.098 45.8513 166.668 48.6013 156.007C51.1287 143.62 58.2451 134.167 69.0242 128.298C79.2973 122.704 91.8298 120.927 104.663 121.133C130.296 121.544 162.817 130.018 196.642 142.03C255.839 163.051 323.22 196.462 373.903 224.503C371.032 159.046 362.63 111.194 351.218 78.6184C338.048 41.0261 322.639 28.3934 311.763 26.5017C310.883 26.3486 308.126 26.2551 302.896 31.5762C297.775 36.787 292.452 45.1596 287.333 55.8453Z"
      fill="url(#paint2_linear_1144_23329)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.059 448.966C142.324 447.049 170.406 440.378 188.857 434.748C196.121 432.531 203.891 436.361 206.211 443.301C208.531 450.242 204.523 457.665 197.259 459.882C177.66 465.862 147.409 473.122 120.335 475.261C106.904 476.322 93.2144 476.216 81.7055 473.497C70.3501 470.815 58.0106 464.729 53.2658 451.595C44.2788 426.716 58.3368 397.262 87.025 366.956C116.585 335.728 165.376 299.509 236.565 259.361L258.155 247.186L257.385 271.127C255.553 328.159 257.284 411.924 268.242 478.172C273.762 511.539 281.415 539.082 291.281 556.525C296.206 565.233 301.019 570.216 305.192 572.612C308.732 574.645 312.496 575.265 317.75 573.55L318.257 573.385L318.775 573.258C320.429 572.852 324.387 570.514 329.911 563.346C335.039 556.692 340.08 547.762 344.607 537.997C349.098 528.31 352.882 518.238 355.612 509.57C358.431 500.619 359.846 494.047 360.081 491.085C360.659 483.82 367.291 478.378 374.895 478.93C382.499 479.482 388.195 485.818 387.618 493.083C387.146 499.014 384.997 507.836 382.056 517.172C379.027 526.791 374.855 537.901 369.857 548.682C364.896 559.384 358.915 570.209 352.149 578.989C345.883 587.12 337.259 595.744 326.204 598.67C313.685 602.626 301.591 601.327 290.967 595.227C280.841 589.413 273.084 579.851 266.986 569.069C254.805 547.533 246.594 516.32 240.965 482.29C231.114 422.736 228.537 350.31 229.249 294.408C171.045 328.817 131.583 359.219 107.507 384.653C79.7232 414.004 75.7661 433.009 79.3705 442.987C79.6623 443.794 80.9273 446.134 88.3348 447.884C95.5887 449.598 105.821 449.933 118.059 448.966Z"
      fill="url(#paint3_linear_1144_23329)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M554.916 395.307C540.297 376.703 519.436 357.544 504.624 345.62C498.793 340.925 498.049 332.603 502.962 327.031C507.876 321.459 516.587 320.748 522.419 325.443C538.152 338.109 560.69 358.71 577 379.466C585.092 389.764 592.287 400.891 596.005 411.646C599.674 422.258 600.852 435.478 591.742 446.308C574.485 466.822 540.904 471.135 498.779 464.069C455.373 456.789 397.306 436.614 323.834 400.416L301.552 389.439L323.173 377.314C374.678 348.431 447.97 302.433 500.83 258.298C527.455 236.069 547.782 215.215 557.983 197.949C563.076 189.33 564.929 182.784 564.83 178.134C564.746 174.188 563.291 170.814 558.975 167.477L558.559 167.156L558.17 166.804C556.929 165.683 552.75 163.727 543.459 163.076C534.833 162.472 524.238 163.149 513.176 164.687C502.202 166.212 491.265 168.515 482.131 170.922C472.7 173.408 466.124 175.763 463.374 177.149C456.63 180.55 448.277 178.084 444.717 171.64C441.158 165.197 443.739 157.216 450.483 153.816C455.989 151.039 464.949 148.081 474.787 145.488C484.922 142.816 496.986 140.275 509.199 138.577C521.323 136.891 534.098 135.965 545.479 136.762C556.019 137.5 568.251 139.883 576.73 147.266C586.9 155.284 592.188 165.757 592.44 177.597C592.68 188.882 588.338 200.246 582.032 210.919C569.437 232.236 546.155 255.495 519.002 278.166C471.483 317.841 408.687 358.486 358.78 387.674C420.255 416.425 468.201 432.153 503.554 438.082C544.351 444.925 563.295 438.007 570.216 429.779C570.777 429.113 572.176 426.844 569.783 419.922C567.439 413.143 562.288 404.69 554.916 395.307Z"
      fill="url(#paint4_linear_1144_23329)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1144_23329"
        x1="39.5256"
        y1="247.983"
        x2="39.5256"
        y2="346.526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252BE1" />
        <stop offset="1" stopColor="#0084FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1144_23329"
        x1="602.631"
        y1="247.983"
        x2="602.631"
        y2="346.526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252BE1" />
        <stop offset="1" stopColor="#0084FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1144_23329"
        x1="225.242"
        y1="0"
        x2="225.242"
        y2="271.806"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252BE1" />
        <stop offset="1" stopColor="#131668" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1144_23329"
        x1="16.5781"
        y1="442"
        x2="310.578"
        y2="316"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252BE1" />
        <stop offset="1" stopColor="#25E1BF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1144_23329"
        x1="410.578"
        y1="136"
        x2="524.578"
        y2="550"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0084FF" />
        <stop offset="1" stopColor="#252BE1" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
