import styled from "@emotion/styled";

export const DescriptionWrapper = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #a6acb7;
	height: 70px;
	overflow-wrap: break-word;
`;

export const LinksWrapper = styled.div`
	margin-top: 20px;
	display: flex;
`;

export const LinkWrapper = styled.div`
	width: 45%;
	justify-content: center;
	margin-right: 20px;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Heading = styled.h1`
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 0px;
`;
