import styled from "@emotion/styled";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Heading = styled.h1`
	font-size: 28px;
	font-weight: bold;
	text-transform: capitalize;
`;

export const SubHeading = styled.h3`
	font-size: 14px;
`;
export const MainLeft = styled.div`
	width: 50%;
`;

export const MainRight = styled.div`
	width: 50%;
`;

export const HeadingMainContainer = styled.p`
	color: #252ce1;
	font-size: 16px;
	font-weight: bold;
`;
