import React, { FC, useState } from "react";
import { useQuery, gql, useMutation  } from "@apollo/client";
import { Spin, Modal, notification } from "antd";
import { Heading, Container } from "./styled";
import { WorkspaceCards } from "./workspace-cards";
import { CreateWorkspaceForm } from "../../components/create-workspace";
import { logout } from "../../helpers/util";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

//This page should not care which client we use
// use hooks

const GET_MY_WORKSPACES = gql`
  query USER_WORKSPACES($after: String,$before: String) {
    getWorkspaces(first: 6,after: $after,before: $before) {
      pageInfo {
       prevPageCursor
       nextPageCursor
      }
      nodes {
        id
        ... on Workspace {
          workspaceId
          name
          description
          executorCount
          workflowCount
        }
      }
    }
  }
`;

const DELETE_WORKSPACE = gql`
	mutation deleteWorkspaces(
		$ids: [String!]!
	) {
		deleteWorkspaces(
			ids: $ids
		) {
			success
			errors {
				errorCode
				errorMessage
			}
		}
	}`;
    

export interface Workspace {
  workspaceId: string;
  name: string;
  description: string;
  numberOfWorkflows: number;
  numberOfTaskExecutors: number;
}

export const DashboardPage: FC<{}> = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    logout();
  };
  const [afterPage, setAfterPage] = useState<any>(null)
  const [beforePage, setBeforePage] = useState<any>(null)
  const { loading, error, data, refetch } = useQuery(GET_MY_WORKSPACES, {
    variables: { after:afterPage,before:beforePage },
  });

  const [deleteWorkspace, {error:deleteError, data:deleteData,loading:deleteLoading}]  = useMutation(DELETE_WORKSPACE);

  const deleteWS =(id:any) =>{
	
    Modal.confirm({
      title: 'Are you sure?',
      content: "The workspace and all task executors and workflows in it will be deleted permanently. \n This action is not reversible.",
      okText:'Delete',
      onOk() {
        deleteWorkspace({variables:{
          ids:[id]
        }}).then((res) => {
          
          if (_.get(res,'data.deleteWorkspace.errors')==null) {
            notification["success"]({
            description:"Workspace deleted successfully",
            message: "Success",
          });
          
          refetch();
          }else if(_.get(res,'data.deleteWorkspace.errors[0].errorMessage') ){
            notification.error({
                  message: 'Error',
                  description: _.get(res,'data.deleteWFRuns.errors[0].errorMessage'),
                });
            }
          
        })
        .catch((err) => {
          notification["error"]({
            message: "Some Error Occured",
            description: err?.message,
          });
        });},
      cancelButtonProps: {
        disabled: false
      },
      // confirmLoading:true,
      cancelText: 'Cancel'
    })
  
  }

  const openWSSettings = (id:string) => {
    navigate(`/workspacesettings/${id}/`);

  }

  if (loading || deleteLoading)
    return (
      <div className="center-loader">
        <Spin size="large" />
      </div>
    );
  if (error)
    return (
      <p>
        Your session has expired, please <b onClick={handleLogOut}>login</b>{" "}
        again
      </p>
    );
  const onChangeAfterBeforePage=(type:any)=>{
    
    if(_.get(data,'getWorkspaces.pageInfo')) {
      if (type=='next' &&_.get(data,'getWorkspaces.pageInfo.nextPageCursor')){
        setBeforePage(null)
        setAfterPage(_.get(data,'getWorkspaces.pageInfo.nextPageCursor'))
      }else if(type=='prev' &&_.get(data,'getWorkspaces.pageInfo.prevPageCursor')){
        setAfterPage(null)
        setBeforePage(_.get(data,'getWorkspaces.pageInfo.prevPageCursor'))
      } 
    }
  }

  const workspaces: Workspace[] = data.getWorkspaces.nodes.map((item: any) => {
    return {
      workspaceId: item.workspaceId,
      name: item.name,
      description: item.description,
      numberOfWorkflows: item.workflowCount,
      numberOfTaskExecutors: item.executorCount,
    };
  });

  return (
    <>
      <Container>
        <Heading>Home</Heading>
        <CreateWorkspaceForm refetch={refetch} />
      </Container>

      <WorkspaceCards data_details={data} 
      workspaces={workspaces} 
      onChangeAfterBeforePage={(e:any)=>onChangeAfterBeforePage(e)} 
      deleteWS={deleteWS}
      openWSSettings={openWSSettings}/>
    </>
  );
};
