import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Select } from "antd";
import { useLocation } from "react-router-dom";
import "./index.css";
import { logout } from "../../helpers/util";

const GET_MY_WORKSPACES = gql`
  query USER_WORKSPACES($search: String) {
    getWorkspaces(search: $search, first: 10) {
      pageInfo {
        prevPageCursor
        nextPageCursor
      }
      nodes {
        id
        ... on Workspace {
          workspaceId
          name
        }
      }
    }
  }
`;

export const SearchWorkspace = (props: any) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [filter, setFilter] = useState<string>(
    queryParams.get("workspaceId") || ""
  );

  const handleLogOut = () => {
    logout();
  };

  const { error, data, loading } = useQuery(GET_MY_WORKSPACES, {
    variables: { search: searchFilter },
    fetchPolicy: "cache-and-network",
  });

  if (!data && loading) return null;

  if (error)
    return (
      <p>
        Your session has expired, please <b onClick={handleLogOut}>login</b>{" "}
        again
      </p>
    );

  const workspaces = data?.getWorkspaces?.nodes?.map((item: any) => {
    return {
      name: item.name,
      key: item.workspaceId,
    };
  });

  //   const handleChange = (value: any) => {
  //     setFilter(value);
  //     const params = new URLSearchParams();
  //     params.append("workspaceId", value);
  //     params.append("search",queryParams.get("search")||'');
  //     history.push({ search: params.toString() });
  //   };

  const onWsSearch = (val: string) => {
    setSearchFilter(val);
  };

  const { Option } = Select;

  return (
    <>
      <Select
        showSearch
        bordered={false}
        defaultValue={filter ? filter : "All Workspace"}
        className="default-select"
        onChange={props.handleChange}
        optionFilterProp="children"
        onSearch={onWsSearch}
        filterOption={(input, option:any) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="">All Workflows</Option>
        {workspaces?.map((data: any) => (
          <Option key={data.key} value={data.key}>
            {data.name}
          </Option>
        ))}
      </Select>
    </>
  );
};
