import React, { FC } from "react";
import CesiumLogo from "../../assets/logo-icon";
import { LogoWrapper } from "./styled";

export const Logo: FC<{}> = () => {
  return (
    <LogoWrapper>
      <CesiumLogo />
      &nbsp;Cesium Scheduler
    </LogoWrapper>
  );
};
