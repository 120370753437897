import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { format } from "date-fns";
import { Spin, Table, Button, Drawer, Space, Row, Col, Modal, notification, Empty, Avatar } from "antd";
import "./index.css";
import { execStatusMap } from "../../assets/dictionary/exec-status";
import { execIconMap } from "../../assets/dictionary/exec-icon";
import { execTypeMap } from "../../assets/dictionary/exec-type";
import _ from "lodash";
import { DeleteOutlined, LeftOutlined, RightOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { logout } from "../../helpers/util";
import { config } from "../../conf";
import moment from 'moment';


const GET_WORKFLOW_BY_ID = gql`
	query($id: String!,$after: String,$before: String) {
		getWorkflowById(id: $id) {
			id
			wfRuns(first: 12,after: $after,before: $before) {
				pageInfo {
					prevPageCursor
					nextPageCursor
				  }
				nodes {
					id
					__typename
					... on WFRun {
						runId
						scheduledTime
						startTime
						endTime
						execType
						execStatus
						wfTaskRuns {
							taskId
							taskName
							taskExecStatus
						}
					}
				}
			}
		}
	}
`;

const GET_WFRUN_DETAIL = gql`
	query($wfRunId: String!) {
		getWFRunDetails(wfRunId: $wfRunId) {
			wfRunId
			workflowId
			description
			userName
			userEmail
			inputs {
				name
				value
			}
			wfRunTaskEvents {
				taskId
				taskName
				status
				eventTime
				logPath
			}
		}
	}
`;
const DELETE_WORKFLOW_RUN_HISTORY = gql`
	mutation deleteWFRuns(
		$workflowRunIds: [String]
	) {
		deleteWFRuns(
			workflowRunIds: $workflowRunIds
		) {
			success
			errors {
				errorCode
				errorMessage
			}
		}
	}
`;

type DrawerState = "visible" | "hidden";

const DrawerContent: FC<{ currentId: any }> = ({ currentId }) => {
	const handleLogOut = () => {
		logout();
	};

	const downloadLogForTask = async (filename: String) => {
		console.log(`Downloading file : ${filename}`);

		const tokenString = localStorage.getItem("token") || "";
		const userToken = JSON.parse(tokenString);

		const response = await fetch(
			`${config.api_url}/api/v1/downloadLogFile/${filename}`,
			{
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					Authorization: userToken,
				},
				redirect: "follow",
				referrerPolicy: "no-referrer",
			}
		);

		const blob = await response.blob();
		const url = window.URL.createObjectURL(new Blob([blob]));

		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${filename}`);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();
		if (link && link.parentNode) link.parentNode.removeChild(link);
	};

	const { loading, error, data } = useQuery(GET_WFRUN_DETAIL, {
		variables: { wfRunId: currentId },
	});


	if (loading)
		return (
			<div className="center-loader">
				<Spin size="large" />
			</div>
		);
	if (error || (!loading && !data?.getWFRunDetails))
		return (
			<p>
				An unknown error has occured, please try again.
			</p>
		);
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
		},
		{
			title: "Value",
			dataIndex: "value",
		}

	];
	const inputsData = data.getWFRunDetails.inputs;


	let taskEvents = data.getWFRunDetails.wfRunTaskEvents.map((taskEvt: any) => {
		if ((taskEvt.status === "WFEXECSTATUS_SUCCESS" || taskEvt.status === "WFEXECSTATUS_FAILED")) {
			if (taskEvt.logPath === "") {
				const correspondingLogEvt = data.getWFRunDetails.wfRunTaskEvents.find((logEvt: any) => {
					return logEvt.taskName === taskEvt.taskName && logEvt.status === taskEvt.status && logEvt.logPath != "";
				});
				if (correspondingLogEvt) {
					return {
						taskId: taskEvt.taskId,
						taskName: taskEvt.taskName,
						status: taskEvt.status,
						eventTime: taskEvt.eventTime,
						logPath: correspondingLogEvt.logPath
					};
				} else {
					return taskEvt;
				}
			} else {
				return null;
			}
		} else {
			return taskEvt;
		}
	});

	taskEvents = taskEvents.filter((taskEvt: any) => { return taskEvt != null })
	return (
		<div>
            <Row gutter={16}>
				<Col span={24}>
					<p className="mb0 input-label">Workflow Run Id</p>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<input
						readOnly={true}
						type="text"
						value={currentId}
						className="input-field"
					/>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<p className="mb0 input-label">Description</p>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<input
						readOnly={true}
						type="text"
						value={data.getWFRunDetails.description}
						className="input-field"
					/>
				</Col>
			</Row>
			<br />


			{
				!_.isEmpty(inputsData) ?
					(
						<>
							<Row gutter={[16, 10]}>
								<Col span={24}>
									<p className="mb0 input-label">Inputs</p>

								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Table
										pagination={false}
										rowClassName={(record, index) =>
											index % 2 === 0 ? "table-row-light" : "table-row-dark"
										}
										columns={columns}
										dataSource={inputsData}
									/>
								</Col>
							</Row>
						</>) :
					<br />
			}
			<br />
			<Row gutter={[16, 10]}>
				<Col span={24}>
					<p className="mb0 input-label">Workflow run timeline</p>

				</Col>
			</Row>

			{
				taskEvents.map((item: any, idx: any) => {
					const Icon = execIconMap(item.status);
					const date = new Date(item.eventTime);
					return (
						<div key={idx}>
							<div className="flex">
								<div
									className="flex flex-column"
									style={{ justifyContent: "center" }}
								>
									<span style={{ height: "18px", width: "18px" }}>
										<Icon />
									</span>
									{idx !==
										taskEvents.length -
										1 && (
											<hr
												style={{
													height: "30px",
													border: "1px dashed #818897",
												}}
											/>
										)}
								</div>
								<span className="ml3 ttc b">
									{item.taskName} - {execStatusMap(item.status)}{" "}
								</span>
								<span className="ml1 gray">
									{format(date, "dd MMM yyyy hh:mm:ss a")}
								</span>
								{
									(item.logPath !== "") ?
										<span className="ml1 gray">
											<Button className="mb-2" icon={<CloudDownloadOutlined />} onClick={() => { downloadLogForTask(item.logPath) }} type="ghost">Download Logs</Button>
										</span>
										: null
								}
							</div>
							<div className="flex items-center"></div>
						</div>
					);
				})}

			<br />
			{data?.getWFRunDetails?.userName && 
			<>
			<Row gutter={[16, 10]}>
				<Col span={24}>
					<p className="mb0 input-label">Process initiated by</p>
				</Col>
			</Row>
			<div className="user-name">
				<Avatar size={40}>{data?.getWFRunDetails?.userName?.charAt(0)}</Avatar>
				<span className="name-margin">
					<div className="ml1 ttc b">{data?.getWFRunDetails?.userName}</div>
					<div className="ml1 gray">{data?.getWFRunDetails?.userEmail}</div>
				</span>
			</div>
			</>}

		</div>
	);
};

const History: FC<{}> = () => {
	const params = useParams();
	const [drawerState, setDrawerState] = useState<DrawerState>("hidden");
	const [currentRunId, setCurrentRunId] = useState("");
	const [afterPage, setAfterPage] = useState<any>(null)
	const [beforePage, setBeforePage] = useState<any>(null)
	const { workflowsId }: any = params;
	const [deleteId, setDeleteId] = useState<String>("")
	const [deleteWFRuns, { error: deleteError, data: deleteData, loading: deleteLoading }] = useMutation(DELETE_WORKFLOW_RUN_HISTORY);
	const handleLogOut = () => {
		logout();
	};
	const queryParams = new URLSearchParams(useLocation().search);

	const wfrunid = queryParams.get("wfRunId") || "";
	const navigate = useNavigate();



	const { loading, error, data: firstData, refetch } = useQuery(
		GET_WORKFLOW_BY_ID,
		{
			variables: { id: workflowsId, after: afterPage, before: beforePage },
			fetchPolicy: "cache-and-network",
		}
	);


	useEffect(() => {
		if (wfrunid && !loading && firstData?.getWorkflowById) {
			setCurrentRunId(wfrunid);
			showDrawer();
		}
	}, [loading])

	if (loading)
		return (
			<div className="center-loader">
				<Spin size="large" />
			</div>
		);
	if (error)
		return (
			<p>
				Your session has expired, please{" "}
				<b onClick={handleLogOut}>login</b> again
			</p>
		);

	const onChangeAfterBeforePage = (type: any) => {
		if (_.get(firstData, 'getWorkflowById.wfRuns.pageInfo')) {
			//console.log('====================================')	
			// console.log(_.get(firstData,'getWorkflowById.wfRuns.pageInfo.nextPageCursor'))	
			// console.log('====================================')	
			if (type == 'next' && _.get(firstData, 'getWorkflowById.wfRuns.pageInfo.nextPageCursor')) {
				setBeforePage(null)
				setAfterPage(_.get(firstData, 'getWorkflowById.wfRuns.pageInfo.nextPageCursor'))
			} else if (type == 'prev' && _.get(firstData, 'getWorkflowById.wfRuns.pageInfo.prevPageCursor')) {
				setAfterPage(null)
				setBeforePage(_.get(firstData, 'getWorkflowById.wfRuns.pageInfo.prevPageCursor'))
			}
		}
	};

	const onClose = () => {

		if (queryParams.has('wfRunId')) {
			queryParams.delete('wfRunId');
			const params = new URLSearchParams();
			navigate({ search: params.toString() });
		}

		setDrawerState("hidden");
	};

	const showDrawer = () => {
		setDrawerState("visible");
	};

	const ShowDrawerWrapper = (ev: any) => {
		const id = ev.currentTarget.dataset.id;
		setCurrentRunId(id);
		showDrawer();
	};

	const { getWorkflowById }: any = firstData;

	const wfRuns = getWorkflowById.wfRuns.nodes.map((item: any) => {
		return {
			scheduledTime: item?.scheduledTime,
			startTime: item?.startTime,
			endTime: item?.endTime,
			execType: execTypeMap(item?.execType),
			taskStatus: item.wfTaskRuns,
			execStatus: item?.execStatus,
			runId: item.runId,
		};
	});

	const deleteRunHistory = (values: any) => {

		Modal.confirm({
			title: 'Are you sure?',
			content: "Once deleted, you will not be able to recover",
			okText: 'Delete',
			onOk() {
				deleteWFRuns({
					variables: {
						workflowRunIds: [_.get(values, 'runId')]
					}
				}).then((res) => {

					if (_.get(res, 'data.deleteWFRuns.errors') == null) {
						notification["success"]({
							description: "Workflow Run history deleted successfully",
							message: "Success",
						});

						refetch();
					} else if (_.get(res, 'data.deleteWFRuns.errors[0].errorMessage')) {
						notification.error({
							message: 'Error',
							description: _.get(res, 'data.deleteWFRuns.errors[0].errorMessage'),
						});
					}

				})
					.catch((err) => {
						notification["error"]({
							message: "Some Error Occured",
							description: err?.message,
						});
					});
			},
			cancelButtonProps: {
				disabled: false
			},
			// confirmLoading:true,
			cancelText: 'Cancel'
		})

	}

	const columns = [
		{
			title: "Request Time",
			dataIndex: "scheduledTime",
		},
		{
			title: "Start Time",
			dataIndex: "startTime",
			sorter: (a: any, b: any) => _.get(a, 'startTime') && _.get(b, 'startTime') && moment(a.startTime, 'DD/MM/YYYY, hh:mm:ss').unix() - moment(b.startTime, 'DD/MM/YYYY, hh:mm:ss').unix(),
			ellipsis: true
		},
		{
			title: "End Time",
			dataIndex: "endTime",
		},
		{
			title: "Status",
			dataIndex: "execStatus",
		},
		{
			title: "Task Status",
			dataIndex: "taskStatus",
		},
		{
			title: "Workflow Execution Type",
			dataIndex: "execType",
			width: "190px"
		},
		{
			title: 'Action',
			key: 'action',
			render: (text: any, record: any) => (
				<Space size="middle">
					<div><DeleteOutlined onClick={() => deleteRunHistory(record)} /></div>
				</Space>
			),
		},
	];

	const dataforTable: any[] = [];

	for (let i = 0; i < wfRuns.length; i++) {
		const Icon = execIconMap(wfRuns[i].execStatus);
		dataforTable.push({
			key: i,
			scheduledTime: wfRuns[i].scheduledTime
				? new Date(wfRuns[i].scheduledTime).toLocaleString()
				: "---",
			startTime: wfRuns[i].startTime
				? new Date(wfRuns[i].startTime).toLocaleString()
				: "---",
			endTime: wfRuns[i].endTime
				? new Date(wfRuns[i].endTime).toLocaleString()
				: "---",
			execType: wfRuns[i].execType,
			taskStatus: (
				<div
					data-id={wfRuns[i].runId}
					className="pointer"
					onClick={ShowDrawerWrapper}
				>
					<>
						{wfRuns[i].taskStatus.slice(0, 3).map((item: any) => (
							<span
								key={item.taskId}
								className={`tab ${execStatusMap(
									item.taskExecStatus
								)}`}
							>
								{item.taskName}
							</span>
						))}
					</>
					{wfRuns[i].taskStatus.length > 3 && (
						<span className="other-count">
							+{wfRuns[i].taskStatus.length - 3} others
						</span>
					)}
				</div>
			),
			execStatus: (
				<div
					data-id={wfRuns[i].runId}
					className="pointer"
					onClick={ShowDrawerWrapper}
				>
					<div className="icon-container">
						<Icon />
						<span>{execStatusMap(wfRuns[i].execStatus)}</span>
					</div>
				</div>
			),
			runId: wfRuns[i].runId
		});
	}

	return (
		<>

			{
				!_.isEmpty(dataforTable) ?
					(
						<Table
							rowClassName={(record, index) =>
								index % 2 === 0 ? "table-row-light" : "table-row-dark"
							}
							columns={columns}
							dataSource={dataforTable}
							pagination={false}
						/>
					) :
					(<Empty />)
			}
			<br />
			{_.isArray(wfRuns) == true && _.size(wfRuns) > 0 && (_.get(firstData, 'getWorkflowById.wfRuns.pageInfo.prevPageCursor') || _.get(firstData, 'getWorkflowById.wfRuns.pageInfo.nextPageCursor')) && <div className="row" style={{ textAlign: 'center' }}>
				<Button icon={<LeftOutlined style={{ marginLeft: '3px' }} />} disabled={_.get(firstData, 'getWorkflowById.wfRuns.pageInfo.prevPageCursor') ? false : true} style={{ marginRight: "25px" }} onClick={() => onChangeAfterBeforePage('prev')}>Previous</Button>
				<Button disabled={_.get(firstData, 'getWorkflowById.wfRuns.pageInfo.nextPageCursor') ? false : true} onClick={() => onChangeAfterBeforePage('next')}>
					Next <RightOutlined style={{ marginLeft: '3px' }} /></Button>
			</div>}
			<Drawer
				title="Workflow Run Timeline"
				width={720}
				onClose={onClose}
				visible={drawerState == "visible"}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<DrawerContent currentId={currentRunId} />
			</Drawer>
		</>
	);
};

export default History;