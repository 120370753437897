//This is getting injected through a runtime script which generates a JS file
// and puts it into the root home directly and gets served statically by nginx
// @ts-ignore
export const config = {
    // @ts-ignore
    api_url: window._env_.API_URL,
     // @ts-ignore
    auth0_domain: window._env_.AUTH0_DOMAIN,
     // @ts-ignore
    auth0_clientId: window._env_.AUTH0_CLIENTID,
     // @ts-ignore
    auth0_redirectUri: window._env_.AUTH0_REDIRECTURI,
    // @ts-ignore
    auth0_audience: window._env_.AUTH0_AUDIENCE,
     // @ts-ignore
     auth0_org: window._env_.AUTH0_ORG,
    
}