const CrossIcon = () => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.23348 5.99925L10.9929 2.2486C11.1575 2.08397 11.25 1.86068 11.25 1.62786C11.25 1.39503 11.1575 1.17175 10.9929 1.00712C10.8282 0.842488 10.605 0.75 10.3721 0.75C10.1393 0.75 9.91603 0.842488 9.7514 1.00712L6.00075 4.76652L2.25009 1.00712C2.08546 0.842488 1.86217 0.75 1.62935 0.75C1.39653 0.75 1.17324 0.842488 1.00861 1.00712C0.84398 1.17175 0.751492 1.39503 0.751492 1.62786C0.751492 1.86068 0.84398 2.08397 1.00861 2.2486L4.76801 5.99925L1.00861 9.74991C0.926666 9.83119 0.861624 9.92789 0.817238 10.0344C0.772852 10.141 0.75 10.2552 0.75 10.3707C0.75 10.4861 0.772852 10.6003 0.817238 10.7069C0.861624 10.8134 0.926666 10.9101 1.00861 10.9914C1.08989 11.0733 1.18658 11.1384 1.29312 11.1828C1.39966 11.2271 1.51393 11.25 1.62935 11.25C1.74476 11.25 1.85904 11.2271 1.96558 11.1828C2.07212 11.1384 2.16881 11.0733 2.25009 10.9914L6.00075 7.23199L9.7514 10.9914C9.83268 11.0733 9.92938 11.1384 10.0359 11.1828C10.1425 11.2271 10.2567 11.25 10.3721 11.25C10.4876 11.25 10.6018 11.2271 10.7084 11.1828C10.8149 11.1384 10.9116 11.0733 10.9929 10.9914C11.0748 10.9101 11.1399 10.8134 11.1843 10.7069C11.2286 10.6003 11.2515 10.4861 11.2515 10.3707C11.2515 10.2552 11.2286 10.141 11.1843 10.0344C11.1399 9.92789 11.0748 9.83119 10.9929 9.74991L7.23348 5.99925Z"
			fill="#818897"
		/>
	</svg>
);

export default CrossIcon;
