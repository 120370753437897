const ErrorIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.56058 0.881714C6.83772 0.746228 7.16187 0.746231 7.439 0.88172L13.0415 3.62078C13.4171 3.80441 13.6388 4.2023 13.5974 4.61831L12.8321 12.2988C12.7959 12.6628 12.5638 12.9779 12.2269 13.1205L7.38956 15.1686C7.14035 15.2741 6.85901 15.2741 6.60979 15.1686L1.77263 13.1205C1.43581 12.9779 1.20373 12.6628 1.16745 12.2988L0.401984 4.61834C0.360521 4.20231 0.582246 3.8044 0.95785 3.62078L6.56058 0.881714Z"
			fill="#E14725"
		/>
		<rect
			x="5.66602"
			y="5.33398"
			width="2.66667"
			height="5.33333"
			rx="1"
			fill="white"
		/>
	</svg>
);

export default ErrorIcon;
