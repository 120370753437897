import Error from "../error-icon";
import Inprogress from "../inprogress-icon";
import Requested from "../requested-icon";
import Success from "../success-icon";
import Started from "../inprogress-icon";
import Expired from "../expired-icons";

export function execIconMap(status: any) {
    switch (status) {
        case "WFEXECSTATUS_REQUESTED":
            return Requested;
        case "WFEXECSTATUS_STARTED":
            return Started;
        case "WFEXECSTATUS_SUCCESS":
            return Success;
        case "WFEXECSTATUS_INPROGRESS":
            return Inprogress;
        case "WFEXECSTATUS_EXPIRED":
            return Expired;

        default:
            return Error;
    }
}
