import { FC, useState, useRef, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import _ from "lodash";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Spin, Table, Breadcrumb, Empty, Space, Button, notification} from "antd";
import EyeIcon from "../../assets/eye";
import {
	Heading,
	Container,
	SubHeading,
	MainLeft,
	MainRight,
	HeadingMainContainer,
} from "./styled";
import { config } from '../../conf'
import "./index.css";
import { logout } from "../../helpers/util";
import { DownloadOutlined,LeftOutlined,RightOutlined} from '@ant-design/icons';
import Password from "antd/lib/input/Password";
const GET_TASK_EXECUTOR_BY_ID = gql`
	query($id: String!,$after: String,$before: String) {
		getTaskExecutorById(id: $id) {
			taskExecutorId
			name
			description
			workflowCount
			executorSysInfo {
				texPassword
			}
			workspace {
				id
			}
			workflows(first:5,after: $after,before: $before) {
				pageInfo {
					prevPageCursor	
					nextPageCursor
				  }
				nodes {
					id
					... on Workflow {
						name
						status
						lastUpdated
					}
				}
			}
		}
	}
`;

const POST_UPDATE_TASK_EXECUTOR = gql`
mutation updateTaskExecutor(
		$taskExecutorId: String!
		$name: String!
		$description: String
		$regeneratePassword:Boolean
	) {
		updateTaskExecutor(
				taskExecutorId: $taskExecutorId
				description: $description
				name: $name
				regeneratePassword: $regeneratePassword
		) {
			success
			errors {
			  errorCode
			  errorMessage
			}
			result {
			  ... on TaskExecutor {
				taskExecutorId
				name
				description
			  }
			}
		}
	}
`;


// @ts-ignore
export const TaskExecutorsDetail = (props: any) => {
	const params = useParams();
	const { taskexecutorsId }: any = params;
	const [isHide, setHide] = useState(true);

	const [ edit, setEdit ] = useState(false);
	const [ cancelEdit, setCancelEdit ] = useState(false);
	const [ saved, setSaved ] = useState(false);
	const [name, setName] = useState(null);
	const [description, setDescription] = useState(null);
	const [texPassword, setTexPassword] = useState(null);


	const [afterPage, setAfterPage] = useState<any>(null)
  	const [beforePage, setBeforePage] = useState<any>(null)

	const isEdit = edit;

	const getFormFieldsValue = (currentFormData:any, pickAttrib: any = []) => {
		let customizedFormData:any = {
			name,
			description,
			texPassword
		};
		customizedFormData = _.pickBy(customizedFormData, fieldValue => !_.isNull(fieldValue));
	  
		let originalFormData = {
			...currentFormData
		};
		originalFormData.texPassword = _.get(currentFormData, 'executorSysInfo.texPassword', '');
	  
		let finalFormData = {
			...originalFormData,
			...customizedFormData
		};
	  
	   return !_.isEmpty(pickAttrib) ? _.pick(finalFormData, pickAttrib): finalFormData;
	};	 

	const getSpecificFormFieldValue = (fieldName:string, currentFormData:any) => {
		return _.get(getFormFieldsValue(currentFormData), fieldName, '');
	};	

	const setFormFieldValue = (fieldName:string, fieldValue:any) => {
        switch (fieldName) {
            case "name":
                setName(fieldValue);
                break;
            case "description":
                setDescription(fieldValue);
                break;
            case "texPassword":
                setTexPassword(fieldValue);
                break;
            default:
                break;
        }
    };

	const submitFormData = (currentFormData:any) => {
		let finalFormData = {
			...getFormFieldsValue(currentFormData, ["name","description","taskExecutorId"]),
			regeneratePassword: false
		};
		onFinish(finalFormData);
	};	 

	function downloadTaskExecutorsDetailsTxt(filename:any, text:any) {
		var element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
		element.setAttribute('download', filename);
	  
		element.style.display = 'none';
		document.body.appendChild(element);
	  
		element.click();
	  
		document.body.removeChild(element);
	};	
	
	
	const getTaskExecutorDownloadDetailsData = (currentFormData:any) => {
		currentFormData.tenantRefId = _.get(JSON.parse(localStorage.getItem("user") || '{}'), 'tenantRefId');
		return _.chain(getFormFieldsValue(currentFormData))
			.pick(["taskExecutorId", "texPassword", "tenantRefId"])
			.map((fieldValue, fieldName) => {
				fieldName = _.eq(fieldName, 'taskExecutorId') ? 'texId': fieldName;
				fieldName = _.eq(fieldName, 'texPassword') ? 'password': fieldName;
				return `${fieldName}: ${fieldValue}`;
			})
			.join('\n')
			.value();
	};	 
	
	const handleCancelEdit = () => {
		setCancelEdit(!cancelEdit);
		setEdit(!edit);
	};

	const handleSave = () => {
		setSaved(!saved);
		setEdit(!edit);
	};

	const handleLogOut = () => {
		logout();
	};

	const [updateTaskExecutorGQL, { data }] = useMutation(POST_UPDATE_TASK_EXECUTOR);
	const { loading, error, data:firstData, refetch } = useQuery(GET_TASK_EXECUTOR_BY_ID, {
		variables: { id: taskexecutorsId, after:afterPage, before:beforePage },
		fetchPolicy: "network-only",
	});

	if (loading)
		return (
			<div className="center-loader">
				<Spin size="large" />
			</div>
		);
	if (error)
		return (
			<p>
				Your session has expired, please{" "}
				<b onClick={handleLogOut}>login</b> again
			</p>
		);

		const onChangeAfterBeforePage=(type:any)=>{	
			if(_.get(firstData,'getTaskExecutorById.workflows.pageInfo')) {	
				
				if (type=='next' &&_.get(firstData,'getTaskExecutorById.workflows.pageInfo.nextPageCursor')) {	
					setBeforePage(null)	
					setAfterPage(_.get(firstData,'getTaskExecutorById.workflows.pageInfo.nextPageCursor'))	
				}else if(type=='prev' &&_.get(firstData,'getTaskExecutorById.workflows.pageInfo.prevPageCursor')){	
					setAfterPage(null)	
					setBeforePage(_.get(firstData,'getTaskExecutorById.workflows.pageInfo.prevPageCursor'))	
				} 	
			}	
		};

	const { getTaskExecutorById }: any = firstData;

	const workflows = getTaskExecutorById.workflows.nodes.map((item: any) => {
		return {
			name: item?.name,
			id: item?.id,
			last: item?.lastUpdated,
			status: item?.status,
		};
	});

	const onFinish = (values: any) => {
		updateTaskExecutorGQL({
			variables: {...values
			},
		})
		.then((res) => {
			if (_.get(res,'data.updateTaskExecutor.success')==true) {
				setEdit(!edit)
				refetch();
			notification["success"]({
				message: " Task Executor Edit successfully",
			});
			}
			
		})
		.catch((err) => {
			notification["error"]({
				message: "Some Error Occured",
				description: err?.message,
			});
		});
	};
	
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
		},
		{
			title: "Status",
			dataIndex: "status",
		},
		{
			title: "Last Updated",
			dataIndex: "last",
		},
		{
			title: "Action",
			dataIndex: "id",
			render: (data: string) => (
				<Link to={`/workflows/${data}`}>View Details</Link>
			),
		},
	];

	const dataforTable: any[] = [];

	for (let i = 0; i < workflows.length; i++) {
		dataforTable.push({
			key: i,
			name: workflows[i]?.name,
			status: workflows[i]?.status,
			id: workflows[i]?.id,
			last: new Date(workflows[i]?.last).toLocaleString(),
		});
	}

	const togglePassword = () => {
		setHide((value) => !value);
	};

	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item>
					<Link to="/taskexecutors">Task Executors</Link>
				</Breadcrumb.Item>

				<Breadcrumb.Item>Task Executors Details</Breadcrumb.Item>
			</Breadcrumb>
			<Container>
				<Heading>{getTaskExecutorById?.name}</Heading>
			</Container>
			<SubHeading>ID: {getTaskExecutorById?.taskExecutorId}</SubHeading>
			<Container>
				<MainLeft>
					<HeadingMainContainer>Details</HeadingMainContainer>
					<div className="mb3">
						<p className="mb0 input-label">Name</p>
						<input
							readOnly={!edit}
							type="text"
							value={getSpecificFormFieldValue("name",getTaskExecutorById)}
							className="input-field"
							onChange={e=>setFormFieldValue("name", e.target.value)}
						/>
					</div>
					<div className="mb3">
						<p className="mb0 input-label">Description</p>
						<input
							readOnly={!edit}
							type="text"
							value={getSpecificFormFieldValue("description",getTaskExecutorById)}
							className="input-field"
							onChange={e=>setFormFieldValue("description", e.target.value)}
						/>
					</div>
					<div style={{ position: "relative" }} className="mb3">
						<p className="mb0 input-label">Authentication code</p>
						<input
							readOnly={!edit}
							type={isHide ? "password" : "text"}
							value={
								getSpecificFormFieldValue("texPassword",getTaskExecutorById)
							}
							className="input-field"
						/>
						<span className="eye" onClick={togglePassword}>
							<EyeIcon />
						</span>
						
					</div>
					<div style={{marginBottom:"20px"}} ><Button className="mb-2" icon={<DownloadOutlined />} type="ghost" onClick={()=>{
						downloadTaskExecutorsDetailsTxt("config.properties", getTaskExecutorDownloadDetailsData({...getTaskExecutorById,baseURL:`${config.api_url}`}));
						}}>Download</Button></div>
					<Space className="mt-4" style={{marginLeft:"-7px"}} direction="horizontal" size={[8, 16]} wrap>
					
					<br />
						<span>
							{ !isEdit ? (<span><Button type="primary" onClick={e=>setEdit(!edit)}>Edit</Button></span>) :
							(
								<Space>
									<Button type="primary" onClick={()=>{submitFormData(getTaskExecutorById)}}>Save</Button>
									<Button className="btn btn-light" onClick={handleCancelEdit}>Cancel</Button>
								</Space>
							)}  
            			</span>
					</Space>
				</MainLeft>
				<MainRight>
					<HeadingMainContainer>
						{getTaskExecutorById?.workflowCount} Associated
						workflows
					</HeadingMainContainer>
					{
						!_.isEmpty(dataforTable) ? 
						(
							<Table
								rowClassName={(record, index) =>
									index % 2 === 0
										? "table-row-light"
										: "table-row-dark"
								}
								columns={columns}
								dataSource={dataforTable}
								pagination={false}
							/>
							): 
						(   <Empty />)
                    }
					<br />
					<br />
						{ _.isArray(workflows)==true && _.size(workflows)>0 && (_.get(firstData,'getTaskExecutorById.workflows.pageInfo.prevPageCursor') || _.get(firstData,'getTaskExecutorById.workflows.pageInfo.nextPageCursor')) && <div className="row" style={{textAlign:'center'}}>
						<Button icon={<LeftOutlined style={{marginLeft:'3px'}} />}  disabled={_.get(firstData,'getTaskExecutorById.workflows.pageInfo.prevPageCursor')?false:true} style={{marginRight:"25px"}} onClick={()=>onChangeAfterBeforePage('prev')}>Previous</Button>
						<Button  disabled={_.get(firstData,'getTaskExecutorById.workflows.pageInfo.nextPageCursor')?false:true} onClick={()=>onChangeAfterBeforePage('next')}>
						Next <RightOutlined style={{marginLeft:'3px'}} /></Button>
					</div>}
				</MainRight>
			</Container>
		</>
	);
};