import { useState } from "react";
import {
  Col, Collapse, Input, Row, Select, Form,
  Button,
  Spin,
} from 'antd';
import { InfoCircleOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DeleteButton from './delete-button';
import _ from "lodash";
import { gql, useQuery } from "@apollo/client";



const LDAPTask = (props: any) => {
  const GET_REMOTES = gql`
  query GetRemoteSystems($after: String, $before: String, $workspaceId: String) {
      getRemoteSystems( workspaceId:$workspaceId, after: $after, before: $before, first: 30  ){
          pageInfo {
              prevPageCursor
              nextPageCursor
          }
          nodes {
              ... on LDAPRemoteSystem {
                  id
                  name
              }
          }
      }
  }
  `;
  const { workspaceId, item, handleChange, handleDependsOnSelectChange, deleteTask } = props;
  const { Panel } = Collapse;
  const { Option } = Select;
  const [attributesData, setAttributesData] = useState<any>(item.attributes);


  const { data: remotes, loading: loadingRemote } = useQuery(GET_REMOTES, {
    variables: { workspaceId: workspaceId },
    skip: !props.workspaceId
  });

  const addEnvVariable = () => {
    if (_.isArray(attributesData) === true && _.size(attributesData) === 0) {
      setAttributesData([{ name: '', value: '' }])
    } else if (_.isArray(attributesData) === true && _.size(attributesData) > 0) {
      let size_data = _.size(attributesData) - 1
      if (_.get(attributesData[size_data], "name")) {
        let prv_current_data = [...attributesData, { name: '', value: '' }]
        setAttributesData(prv_current_data)
      }
    }
    item.attributes = attributesData
  }
  const onChangeLDAPAttribute = async (e: any, type: String, index: any) => {
    let update_env = attributesData
    if (type === 'name') {
      update_env[index].name = e
    } else {
      update_env[index].value = e
    }
    let final_data = [...update_env]
    setAttributesData(final_data)
    item.attributes = final_data
  }
  const removeEnvVariable = (index: any) => {
    let array = [...attributesData];
    _.remove(array, (item, i) => i === index);
    setAttributesData(array)
    item.attributes = array
  }
  const ldapAttributesView = (data: any) => {

    return _.map(data, (data, index: number) => {
      return <div key={index}>
        {index === 0 ? <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <div className="input-label">Attribute</div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div className="input-label">Value</div>
          </Col>
        </Row> : null}
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Input onChange={(e) => onChangeLDAPAttribute(_.get(e, 'target.value'), 'name', index)} value={_.get(data, 'name')} defaultValue={_.get(data, 'name')} placeholder="Object Class" />
          </Col>
          <Col className="gutter-row" span={12}>
            <div className="KeyValueInputClose">
              <Input onChange={(e) => onChangeLDAPAttribute(_.get(e, 'target.value'), 'value', index)} value={_.get(data, 'value')} defaultValue={_.get(data, 'value')} placeholder="Value" />
              <CloseOutlined onClick={() => removeEnvVariable(index)} />
            </div>
          </Col>
        </Row>
      </div>
    })
  }

  console.log('inside the run LDAPTask task component, item:' + item)
  return (
    <Panel
      {...props}>

      <Row gutter={16} style={{ marginTop: '20px' }} align="middle">
        <Col className="gutter-row" span={6}>
          <label style={{ marginRight: '6px' }}>Name</label>
          <InfoCircleOutlined />
        </Col>
        <Col className="gutter-row" span={12}>
          <Input placeholder="" value={item.name} onChange={(ev: any) => handleChange(ev.target.value, 'name')} style={{ marginTop: '0' }} />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }} align="middle">
        <Col className="gutter-row" span={6}>
          <label style={{ marginRight: '6px' }}>Change Type</label>
          <InfoCircleOutlined />
        </Col>
        <Col span={12}>
          <Select
            placeholder="Select the change type"
            onSelect={(ev: any) => handleChange(ev, 'changeType')}
            style={{ marginTop: '0', width: '100%' }}
            value={item.changeType}
          >
            <Option value='ADD' key='ADD'>
              ADD
            </Option>
            <Option value='ADD_ATTRIBUTES' key='ADD_ATTRIBUTES'>
              ADD ATTRIBUTES
            </Option>
            <Option value='REPLACE_ATTRIBUTES' key='REPLACE_ATTRIBUTES'>
              REPLACE ATTRIBUTES
            </Option>
            <Option value='REMOVE_ATTRIBUTES' key='REMOVE_ATTRIBUTES'>
              REMOVE ATTRIBUTES
            </Option>
            <Option value='DELETE' key='DELETE'>
              DELETE
            </Option>
          </Select>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }} align="middle">
        <Col className="gutter-row" span={6}>
          <label style={{ marginRight: '6px' }}>Remote System</label>
          <InfoCircleOutlined />
        </Col>
        <Col span={12}>
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Please select remote system"
            loading={loadingRemote}
            notFoundContent={loadingRemote ? <Spin size="small" /> : "No Remote Systems found"}
            disabled={!workspaceId}
            onSelect={(ev: any) => handleChange(ev, 'ldapRemoteSystemId') } 
            value={item.ldapRemoteSystemId}
            style={{ marginTop: '0', width: '100%' }}
          >
            {
              remotes?.getRemoteSystems?.nodes.length &&
              remotes?.getRemoteSystems?.nodes.map(
                (remote: any) => (
                  <Option
                    value={remote.id}
                    key={remote.id}
                  >
                    {remote.name}
                  </Option>
                )
              )
            }
          </Select>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }} align="middle">
        <Col className="gutter-row" span={6}>
          <label style={{ marginRight: '6px' }}>Cn</label>
          <InfoCircleOutlined />
        </Col>
        <Col className="gutter-row" span={12}>
          <Input placeholder="" value={item.cn} onChange={(ev: any) => handleChange(ev.target.value, 'cn')} style={{ marginTop: '0' }} />
        </Col>
      </Row>
      <Form.Item
        name="Workflow_Definition"
        label="LDAP Attributes"
        className="w-100 mb-0"
        style={{ marginTop: '20px' }}
      >

        <Button onClick={() => addEnvVariable()} icon={<PlusCircleOutlined />} >Add Attribute</Button>
        <div style={{ padding: 0, border: 'none' }} className="KeyValueContainer">
          {ldapAttributesView(attributesData)}

        </div>
      </Form.Item>

      {(props.definitionData.filter((data: any) => data.id !== item.id && data.name.length > 0)).length > 0 && (
        <Row gutter={16} style={{ marginTop: '20px' }} align="middle">
          <Col className='gutter-row' span={6}>
            <span style={{ marginRight: '6px' }}>Depends On</span>
            <InfoCircleOutlined />
          </Col>
          <Col className='gutter-row' span={10}>
            <Select
              mode="multiple"
              placeholder="Please select multiple"
              onChange={handleDependsOnSelectChange}
              value={item.dependsOn}
              style={{ width: '100%' }}  >
              {props.definitionData.filter((data: any) => data.id !== item.id).map((value: any) => <Option key={value.id}>{value.name}</Option>)}
            </Select>
          </Col>
        </Row>
      )}
      <br />
      <br />
      <DeleteButton deleteTask={deleteTask} />
    </Panel>
  )
}

export default LDAPTask;
