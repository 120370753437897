const ExpiredIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.7829 13.0924H3.48061C2.82271 13.0924 2.19176 12.8311 1.72655 12.3658C1.26135 11.9006 1 11.2697 1 10.6118V5.03042C1 4.37252 1.26135 3.74156 1.72655 3.27636C2.19176 2.81115 2.82271 2.5498 3.48061 2.5498H10.3023C10.9602 2.5498 11.5911 2.81115 12.0564 3.27636C12.5216 3.74156 12.7829 4.37252 12.7829 5.03042V12.7823"
			fill="#acadad"
		/>
		<path
			d="M12.7829 13.0924H3.48061C2.82271 13.0924 2.19176 12.8311 1.72655 12.3658C1.26135 11.9006 1 11.2697 1 10.6118V5.03042C1 4.37252 1.26135 3.74156 1.72655 3.27636C2.19176 2.81115 2.82271 2.5498 3.48061 2.5498H10.3023C10.9602 2.5498 11.5911 2.81115 12.0564 3.27636C12.5216 3.74156 12.7829 4.37252 12.7829 5.03042V12.7823"
			stroke="#acadad"
			strokeWidth="1.24031"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.7207 1V2.24031"
			stroke="#acadad"
			strokeWidth="1.24031"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.06152 1V2.24031"
			stroke="#acadad"
			strokeWidth="1.24031"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.5498 5.34082L11.387 5.34082"
			stroke="white"
			strokeWidth="0.930229"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.1623 14.3324C13.8748 14.3324 15.2631 12.9441 15.2631 11.2316C15.2631 9.51912 13.8748 8.13086 12.1623 8.13086C10.4498 8.13086 9.06152 9.51912 9.06152 11.2316C9.06152 12.9441 10.4498 14.3324 12.1623 14.3324Z"
			fill="white"
			stroke="#acadad"
			strokeWidth="1.24031"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.0933 10.3906L12.1631 11.3209L13.0933 10.3906Z"
			fill="white"
		/>
		<path
			d="M13.0933 10.3906L12.1631 11.3209"
			stroke="#acadad"
			strokeWidth="1.24031"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ExpiredIcon;
