import styled from "@emotion/styled";
import { Button, Col, Input, notification, Row, Select } from "antd";
import { PlusCircleOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { EXEC_STATUS, KEY_VALUE_PAIR } from "..";
import { NotificationsCard } from "../styled";

interface WebhookNotificationProps {
    webhookNotification?: any;
    removeWebhookNotification?: () => void;
    updateWebhookNotification?: (updatedWebhook: any) => void;
}
export const WebhookNotification: React.FC<WebhookNotificationProps> = ({
    webhookNotification,
    removeWebhookNotification,
    updateWebhookNotification,
}) => {
    const [headerEditInProgress, setHeaderEditInProgress] = useState<boolean>(false);
    let headers: KEY_VALUE_PAIR[] = webhookNotification
        ? webhookNotification.webhookNotification?.headers
        : [];
    let execStatuses: EXEC_STATUS[] = webhookNotification ? webhookNotification?.execStatuses : [];
    let uri: string = webhookNotification ? webhookNotification.webhookNotification?.uri : "";

    const onEventSelect = (e: any) => {
        execStatuses = [...execStatuses, e];
        update();
    };

    const onEventDeSelect = (e: any) => {
        execStatuses = execStatuses.filter((es) => es !== e);
        update();
    };

    const addHeaders = () => {
        if (!headerEditInProgress) {
            setHeaderEditInProgress(true);
            headers = [...headers, { name: "", value: "" }];
            update();
        }
    };

    const removeHeader = (index: number) => {
        if (headers[index]?.name === "" || headers[index]?.value === "") {
            setHeaderEditInProgress(false);
        }
        headers = headers.filter((_, i) => i !== index);
        update();
    };

    const updateHeader = (value: string, index: number, type: "NAME" | "VALUE") => {
        if (type === "NAME") {
            headers[index].name = value;
            if (headers[index].name !== "" && headers[index].value !== "")
                setHeaderEditInProgress(false);
        } else {
            headers[index].value = value;
            if (headers[index].name !== "" && headers[index].value !== "")
                setHeaderEditInProgress(false);
        }
        update();
    };

    const update = () => {
        if (updateWebhookNotification)
        {
            updateWebhookNotification({
                channelType: "WEBHOOK",
                execStatuses: execStatuses,
                webhookNotification: { method: "POST", headers: headers, uri: uri },
            });
        }
    };

    const setUri = (updateUri: string) => {
        uri = updateUri;
        update();
    };

    return (
        <NotificationsCard>
            <StyledDelete
                onClick={() => removeWebhookNotification && removeWebhookNotification()}
            />
            <div>
                <span>Select Event</span>
                <StyledSelect
                    onSelect={(e: any) => onEventSelect(e)}
                    onDeselect={(e: any) => onEventDeSelect(e)}
                    mode="multiple"
                    tokenSeparators={[","]}
                    value={execStatuses}
                >
                    <Select.Option value="WFEXECSTATUS_STARTED" key="1">
                        Workflow started
                    </Select.Option>
                    <Select.Option value="WFEXECSTATUS_FAILED" key="2">
                        Workflow failed
                    </Select.Option>
                    <Select.Option value="WFEXECSTATUS_SUCCESS" key="3">
                        Workflow succeeded
                    </Select.Option>
                </StyledSelect>
            </div>
            <StyledContainer>
                <span>Select URL</span>
                <Input
                    placeholder="Enter webhook uri"
                    type="url"
                    onChange={(e) => setUri(e.target.value)}
                    value={uri}
                />
            </StyledContainer>
            <div>
                <Button onClick={() => addHeaders()} icon={<PlusCircleOutlined />}>
                    Add Header
                </Button>
                {headers?.map((header, index) => {
                    return (
                        <div style={{ marginTop: index === 0 ? 0 : 15 }} key={index}>
                            {index === 0 && (
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <div className="input-label">Key</div>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <div className="input-label">Value</div>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Input
                                        value={header.name}
                                        onChange={(e) =>
                                            updateHeader(e.target.value, index, "NAME")
                                        }
                                    />
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <div className="KeyValueInputClose">
                                        <Input
                                            value={header.value}
                                            onChange={(e) =>
                                                updateHeader(e.target.value, index, "VALUE")
                                            }
                                        />
                                        <CloseOutlined onClick={() => removeHeader(index)} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </div>
        </NotificationsCard>
    );
};

const StyledSelect = styled(Select)`
    width: 100%;
    margintop: 8px;
    marginbottom: 8px;
`;

const StyledContainer = styled.div`
    margin: 15px 0px;
`;

const StyledDelete = styled(DeleteOutlined)`
    float: right;
    color: red;
`;
