import { Button } from "antd"
import { useState } from "react"
import SmallDeleteIcon from "../../assets/delete-small-icon";
import TriggerRemoveIcon from "../../assets/trigger-remove-icon"
import {LeftOutlined} from "@ant-design/icons";
const DeleteButton = (props:any) => {
    const [showFull , setShowFull] = useState(false);
    const handleDelete = () => {
        props.deleteTask()
    }

    const toggleShow =() => {
        setShowFull(!showFull);
    }
    return(
        <>
        <Button 
        onClick={toggleShow}
			className="trigger-rmove-btn" 
			icon={<TriggerRemoveIcon />}
            >
            Remove
        </Button>
        {showFull && 
        
        <div className="UpdateCancelBtnSet UserDeleteAction">
			<div className="WorkspaceActionSetWarning">Are you sure want to delete?</div>
			<div className="UpdateCancelBtnContainer">
                <Button onClick={handleDelete} style={{cursor:"pointer"}} danger type="default" className="DeleteBtn">
			        <SmallDeleteIcon /><span>Delete</span>
		        </Button>
		        <Button  onClick={toggleShow} style={{cursor:"pointer"}} type="default" className="CancelBtn" ><LeftOutlined /><span>Cancel</span></Button>
        
        </div>
        </div>
        
        }
        </>
        
    )
}

export default DeleteButton