export function wfStatusMap (status:any) {
    switch (status) {
        case 'WFSTATUS_SCHEDULING_FAILED':
            return 'Scheduling Failed';
            case 'WFSTATUS_DEFINING':
                return 'Defining';
            case 'WFSTATUS_SCHEDULED':
                return 'Scheduled';
            case 'WFSTATUS_ARCHIVED':
                return 'Archived';
            case 'WFSTATUS_PAUSED':
                return 'Paused';
            default :
                return 'Error';
    }
}