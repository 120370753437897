const EyeIcon = () => (
	<svg
		width="18"
		height="14"
		viewBox="0 0 18 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.2731 6.66634C15.5917 2.75801 12.4285 0.333008 9.01567 0.333008C5.60281 0.333008 2.43967 2.75801 0.758214 6.66634C0.712379 6.77148 0.688721 6.88496 0.688721 6.99967C0.688721 7.11439 0.712379 7.22787 0.758214 7.33301C2.43967 11.2413 5.60281 13.6663 9.01567 13.6663C12.4285 13.6663 15.5917 11.2413 17.2731 7.33301C17.319 7.22787 17.3426 7.11439 17.3426 6.99967C17.3426 6.88496 17.319 6.77148 17.2731 6.66634ZM9.01567 11.9997C6.36862 11.9997 3.87973 10.0913 2.43967 6.99967C3.87973 3.90801 6.36862 1.99967 9.01567 1.99967C11.6627 1.99967 14.1516 3.90801 15.5917 6.99967C14.1516 10.0913 11.6627 11.9997 9.01567 11.9997ZM9.01567 3.66634C8.35713 3.66634 7.71338 3.86184 7.16583 4.22811C6.61828 4.59438 6.19151 5.11498 5.9395 5.72406C5.68749 6.33315 5.62155 7.00337 5.75002 7.64997C5.8785 8.29658 6.19561 8.89052 6.66127 9.3567C7.12693 9.82287 7.72021 10.1403 8.36609 10.269C9.01197 10.3976 9.68145 10.3316 10.2899 10.0793C10.8983 9.82698 11.4183 9.39974 11.7841 8.85157C12.15 8.30341 12.3453 7.65894 12.3453 6.99967C12.3453 6.11562 11.9945 5.26777 11.3701 4.64265C10.7456 4.01753 9.89874 3.66634 9.01567 3.66634ZM9.01567 8.66634C8.6864 8.66634 8.36452 8.56859 8.09075 8.38546C7.81697 8.20232 7.60359 7.94202 7.47758 7.63748C7.35158 7.33294 7.31861 6.99783 7.38284 6.67452C7.44708 6.35122 7.60564 6.05425 7.83847 5.82116C8.0713 5.58808 8.36794 5.42934 8.69088 5.36503C9.01382 5.30072 9.34856 5.33373 9.65276 5.45987C9.95696 5.58602 10.217 5.79964 10.3999 6.07372C10.5828 6.34781 10.6805 6.67004 10.6805 6.99967C10.6805 7.4417 10.5051 7.86562 10.1929 8.17818C9.88065 8.49075 9.4572 8.66634 9.01567 8.66634Z"
			fill="#383B40"
		/>
	</svg>
);

export default EyeIcon;
