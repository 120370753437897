import styled from "@emotion/styled";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const MainLeft = styled.div`
	width: 48%;
`;

export const MainRight = styled.div`
	width: 48%;
`;


