import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { Progress } from "antd";

import { config } from "../../conf";
import "./index.css";
import Cross from "../../assets/cross-icon";

import { UploadOutlined } from "@ant-design/icons";

// @ts-ignore
const UPLOAD_URL = config.api_url.concat("/api/v1/workflow/uploadCodeArtifact");

// @ts-ignore
const UploadFiles = (props) => {
	const { changeFileData, data = null, workflowId = "" } = props;

	const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
	const [showLoader, setShowLoader] = useState(false);
	const [showFile, setShowFile] = useState(
		data?.codeArtifactFileName ? true : false
	);
	const [currentFile, setCurrentFile] = useState<any[]>([]);
	const [progress, setProgress] = useState(0);
	const [message, setMessage] = useState("");
	const [responseData, setResponseData] = useState<any>(data);

	function fetchApi(file: any) {
		let currentFile = file;
		const tokenString = localStorage.getItem("token") || "";
		const userToken = JSON.parse(tokenString);

		let formData = new FormData();

		formData.append("file", currentFile);
		return fetch(UPLOAD_URL, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				Authorization: userToken,
			},
			redirect: "follow",
			referrerPolicy: "no-referrer",
			body: formData,
		}).then(async (resp) => {
			if (resp.status < 200 || resp.status > 299) {
				throw resp.status;
			}

			return resp;
		});
	}

	const removeFile = () => {
		setShowFile(false);
		setResponseData(null);
		changeFileData(null);
		setSelectedFiles([]);
		setCurrentFile([]);
	};

	// @ts-ignore
	const upload = async (file) => {
		let currentFile = file[0];

		let message;
		let response;
		if (!currentFile) {
			return null;
		}
		if (
			currentFile.name.includes(".zip") ||
			currentFile.name.includes(".rar") ||
			currentFile.name.includes(".7zip")
		) {
			setProgress(0);
			setCurrentFile(currentFile);
			setShowLoader(true);
			setShowFile(true);

			try {
				response = await fetchApi(currentFile);
			} catch (status) {
				message = status;
			}

			if (response) {
				const jsonResponse = await response.json();
				setResponseData(jsonResponse);
				changeFileData(jsonResponse);

				setShowLoader(false);
			}
			if (message) {
				window.alert("Something Went Wrong");
				setShowLoader(false);
			}
		} else {
			window.alert("Please upload zip file only");
			return null;
		}
	};

	const onDrop = (files: any) => {
		if (files.length > 0) {
			setSelectedFiles(files);
			upload(files);
		}
	};

	const downloadFile = async () => {
		if (!workflowId) return null;

		const tokenString = localStorage.getItem("token") || "";
		const userToken = JSON.parse(tokenString);

		const response = await fetch(
			`${config.api_url}/api/v1/workflow/${workflowId}/downloadCodeArtifact`,
			{
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					Authorization: userToken,
				},
				redirect: "follow",
				referrerPolicy: "no-referrer",
			}
		);

		const blob = await response.blob();
		const url = window.URL.createObjectURL(new Blob([blob]));

		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${data.codeArtifactFileName}`);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();
		if (link && link.parentNode) link.parentNode.removeChild(link);
	};

	return (
		<div>
			{currentFile && (
				<div className="progress mb-3">
					{/*<div
						className="progress-bar progress-bar-info progress-bar-striped"
						role="progressbar"
						aria-valuenow={progress}
						aria-valuemin="0"
						aria-valuemax="100"
						style={{ width: progress + "%" }}
					>
						{progress}%
					</div>*/}
				</div>
			)}
			{showFile ? (
				<div className="file-container">
					<span
						className={` ${
							showLoader ? "" : "file-name-color"
						} file-name`}
						onClick={downloadFile}
					>
						{(selectedFiles &&
							selectedFiles[0] &&
							selectedFiles[0].name) ||
							(responseData && responseData.codeArtifactFileName)}
					</span>

					{showLoader && (
						<div style={{ width: "30%" }}>
							<Progress percent={50} showInfo={false} />
						</div>
					)}
					<span className="cross-icon" onClick={removeFile}>
						<Cross />
					</span>
				</div>
			) : (
				<>
					<Dropzone onDrop={onDrop} multiple={false}>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div
									{...getRootProps({ className: "dropzone" })}
								>
									<input
										{...getInputProps()}
										accept=".zip,.rar,.7zip"
									/>
									{selectedFiles &&
									selectedFiles.length > 0 &&
									selectedFiles[0].name ? (
										<div className="selected-file">
											{selectedFiles &&
												selectedFiles[0].name}
										</div>
									) : (
									<div>	<p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-hint">
                    Drag and Drop here or <b>Browse Files</b>
                  </p>
				  </div>
									)}
								</div>
							</section>
						)}
					</Dropzone>
					<p className="zip-text">Accepted file types: ZIP</p>
				</>
			)}

			<div className="alert alert-light" role="alert">
				{message}
			</div>
		</div>
	);
};

export default UploadFiles;
