import styled from "@emotion/styled";

export const AuthFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
  background: #ffffff;
  min-height: 200px;
  box-shadow: 0px 3px 36px #bec3cf;
  border-radius: 16px;
`;

export const Heading = styled.h1`
  color: #252ce1;
  font-weight: 900;
`;

export const Link = styled.span`
  color: #252ce1;
  cursor: pointer;
`;
