import React from "react";
import { Link } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import "antd/dist/antd.min.css";
import { Layout, Menu } from "antd";

import { FolderOpenOutlined, BranchesOutlined } from '@ant-design/icons';


import {
  
  TaskExecutorsIcon,
  SettingsIcon
  
} from "../../assets/nav-icons";
import { Topbar } from "../../components/top-bar";

import "./index.css";
import { apolloClient } from "../../services/apollo";
const { Header, Sider, Content } = Layout;

export class AuthedPage extends React.Component {
  render() {
    const client = apolloClient;
    const path = window.location.pathname;
    const currentPage = path.includes("workspaces")
      ? "1"
      : path.includes("taskexecutors")
      ? "2"
      : 
      path.includes("workflows")
      ? "3"
      : "4";

    return (
      <ApolloProvider client={client}>
        <Layout className="app_layout">
          <Header className="app_layout" style={{ padding: 0, height: 60 }}>
            <Topbar />
          </Header>
          <Layout>
            <Sider className="app_layout_sidebar">
              <Menu
                mode="inline"
                selectedKeys={[currentPage]}
                className="app_layout_sidebar_menu"
              >
                <Menu.Item
                  key="1"
                  icon={<FolderOpenOutlined style={{ fontSize: '24px', marginRight: '0px' }}/>}
                  className="app_layout_sidebar_menu__item"
                >
                  <Link to="/workspaces" className="blue">
                    Workspaces
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="2"
                  icon={<TaskExecutorsIcon />}
                  className="app_layout_sidebar_menu__item"
                >
                  <Link to="/taskexecutors">Task Executors</Link>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<BranchesOutlined style={{ fontSize: '24px', marginRight: '0px' }}/>}
                  className="app_layout_sidebar_menu__item"
                >
                  <Link to="/workflows">Workflows</Link>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  icon={<SettingsIcon />}
                  className="app_layout_sidebar_menu__item"
                >
                  <Link to="/settings">Settings</Link>
                </Menu.Item>
              </Menu>
            </Sider>

            <Content className="app_layout_content">
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </ApolloProvider>
    );
  }
}
