import { useEffect, useState } from "react";
import { useAuth } from "../../controllers/auth-controller";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { config } from "../../conf";
import { Spin } from "antd";

//This page is called, when auth0 login is successful.
export const Auth0Login = () => {
    let auth = useAuth();
    let navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [auth0Token, setAuth0Token] = useState('');
    //set the auth type and token.
    useEffect(() => {
        const fetchAuth0Token = async () => {
            try {
                // @ts-ignore
                if (auth0Token === '') {
                    let accessToken = await getAccessTokenSilently({ organization: config.auth0_org, ignoreCache: true });

                    accessToken = `Bearer ${accessToken}`;
                    console.log("Auth0 token : " + accessToken);
                    // @ts-ignore
                    auth.setAuthType('auth0');
                    // @ts-ignore
                    auth.setSignedInToken(accessToken);
                    setAuth0Token(auth0Token);
                    navigate("/workspaces", {replace: true});
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchAuth0Token();
    })

    //redirect to dashboard once token is set.

    return (
        <>
            <div className="center-loader">
                <Spin size="large" />
            </div>
        </>
    )
};
