import { Input } from 'antd';
import { AddNewLink, LinkButton, NoLinks } from "./styled"
import { gql, useMutation } from "@apollo/client";
import { List } from 'antd';
import { useState } from 'react';
import "./index.css";
export const JiraCloudUrls = (props: any) => {
    const GET_JIRA_CLD_URLS = gql`
        query {
        getTenant {
            result {
                ... on Tenant {
                jiraCloudURLs
                }        
            }
            success
            errors {
                errorCode
                errorMessage
            }
            }
        }
    `;
    const ADD_JIRA_CLD_URL = gql`
    mutation addJira($jiraUrl: String!) {
        addJiraCloudURL(jiraCloudURL: $jiraUrl) {
            success
            errors {
            errorCode
            errorMessage
            }
        }
        }`
    const DELETE_JIRA_CLD_URL = gql`
    mutation deleteJiraUrl($jiraUrl: String!) {
        removeJiraCloudURL(jiraCloudURL: $jiraUrl) {
          success
          errors {
            errorCode
            errorMessage
          }
        }
      }
      `
    const [addLinkTextDisplay, setAddLinkTextDisplay] = useState<string>('none');
    const [urlValidationErrDisplay, setUrlValidationErrDispaly] = useState<string>('none');
    const [newUrl, setNewUrl] = useState<string>('');

    const [addJiraCloudUrlGQL] = useMutation(ADD_JIRA_CLD_URL, {
        refetchQueries: [
            { query: GET_JIRA_CLD_URLS }
        ],
    });
    const [delJiraCloudUrlGQL] = useMutation(DELETE_JIRA_CLD_URL, {
        refetchQueries: [
            { query: GET_JIRA_CLD_URLS }
        ],
    });
    const onAddNewLink = () => {
        setAddLinkTextDisplay('flex')
    }
    const validateAndFormatUrl = (url: string) => {

        try {
            let domain = (new URL(url)).hostname.replace('www.', '');
            if (new RegExp('(jira-dev.com|.atlassian.net|.atlassian.com|jira.com)', 'igm').test(domain)) {
                setUrlValidationErrDispaly('none')
                return domain;
            }
            else {
                setUrlValidationErrDispaly('block')
                return null;
            }
        } catch (e) {
            setUrlValidationErrDispaly('block')
            return null;
        }
    }
    const addJiraCloudURL = (url: string) => {
        let cleanedURL = validateAndFormatUrl(url);
        if (cleanedURL) {
            addJiraCloudUrlGQL({
                variables: {
                    jiraUrl: cleanedURL
                },
            }).then((res) => {
                if (res.data?.addJiraCloudURL?.success) {
                    setAddLinkTextDisplay('none');
                    setNewUrl('');
                }
            }).catch((err) => {
                console.log("An error occured while adding jira cloud url :" + err);
            });
        }
    }
    const handleUrlEnter = (event: any) => {
        if (event.key === 'Enter') {
            addJiraCloudURL(event.target.value);
        }
    }
    const handleNewUrlChange = (event: any) => {
        setNewUrl(event.target.value);
    }
    const deleteJiraUrl = (url: any) => {
        delJiraCloudUrlGQL({
            variables: {
                jiraUrl: url
            },
        }).then((res) => {
            if (res.data?.removeJiraCloudURL?.success) {
                console.log("Succesfully removed jira cloud url");
            }
        }).catch((err) => {
            console.log("An error occured while adding jira cloud url :" + err);
        });
    }

    const cloud_urls = props.data.getTenant.result?.jiraCloudURLs;

    return (
        <>
            {
                (cloud_urls.length === 0) ?
                    <div>
                        <NoLinks>No Links to show </NoLinks>
                        <br />
                    </div>
                    :
                    <div className='cloud-urls-list'>
                        <List
                            dataSource={cloud_urls}
                            renderItem={(url: any) => (
                                <List.Item>
                                    <div className='url-row'>
                                        <span className='url-span'>{url}</span>

                                        <LinkButton onClick={(e: any) => deleteJiraUrl(url)}>Delete</LinkButton>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
            }

            <AddNewLink onClick={onAddNewLink}><span>&#43;</span> Add new link</AddNewLink>
            <div className='add-new-link-text' style={{ display: addLinkTextDisplay }}>
                <Input type='url' placeholder="enter jira url" onKeyDown={(ev: any) => handleUrlEnter(ev)} value={newUrl} onChange={(e: any) => handleNewUrlChange(e)} />
                <LinkButton onClick={() => addJiraCloudURL(newUrl)}>Save</LinkButton>
                <LinkButton onClick={() => setAddLinkTextDisplay('none')}>Cancel</LinkButton>
            </div>
            <div className='url-validation-err-text' style={{ display: urlValidationErrDisplay }}>Please enter a valid Jira URL</div>
        </>
    );
}
