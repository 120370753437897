// @ts-nocheck

import React from "react";

import { Navigate } from "react-router-dom";
import { useAuth } from "../auth-controller";

export const PrivateRoute = ({children, ...rest}) => {
  const auth = useAuth();
  return auth.token ?
      children :
      <Navigate
        to="/"
      />
};
