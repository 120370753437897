import { ApolloClient, createHttpLink , InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { config } from '../../conf'
// @ts-ignore
const GRAPHQL_BASE_URL = config.api_url.concat('/api/graphql')
console.log('initializing the link')

const httpLink = createHttpLink({
  uri: GRAPHQL_BASE_URL
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  if(token?.startsWith('"')) {
    token = token.replaceAll('"', '');
  } 
  
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    }
  }
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
  });

 