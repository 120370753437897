const JavaIcon = () => (
	<svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.8697 1C11.3505 1.54733 11.5091 2.32997 11.371 3.03588C11.2226 3.82875 10.7827 4.52954 10.2661 5.13315C9.85172 5.6191 9.376 6.04878 8.87982 6.44777C8.30179 6.91326 7.73399 7.41456 7.37081 8.06932C7.06389 8.60131 6.99228 9.25606 7.20201 9.83409C7.54473 10.8265 8.34271 11.5631 8.77751 12.4991C8.29667 12.0746 7.82607 11.6347 7.38616 11.1692C6.92578 10.6781 6.49098 10.1564 6.20453 9.54252C6.01015 9.11283 5.92319 8.61154 6.07153 8.15628C6.21987 7.69079 6.55236 7.31737 6.88486 6.96953C7.81072 6.04367 8.95143 5.36333 9.85684 4.42212C10.3581 3.90548 10.7725 3.28653 10.9157 2.57551C11.0333 2.05375 10.9771 1.51664 10.8697 1Z" fill="#E92E2F" stroke="#E92E2F" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M10.1126 6.8263C11.105 6.09482 12.2662 5.62933 13.4427 5.29172C12.5117 5.88509 11.5398 6.42731 10.7367 7.18949C10.4451 7.47083 10.1689 7.79309 10.041 8.18697C9.94382 8.47854 9.98474 8.8008 10.1024 9.07702C10.3172 9.56809 10.6804 9.96708 10.9566 10.4172C11.1919 10.8009 11.2329 11.2919 11.0538 11.7063C10.7827 12.315 10.2507 12.76 9.69829 13.113C9.62667 13.1539 9.56529 13.2306 9.47833 13.2409C9.84663 12.9084 10.1331 12.4378 10.1229 11.9313C10.1126 11.5528 9.9029 11.2203 9.65736 10.9492C9.15095 10.3456 8.72638 9.57832 8.81334 8.76499C8.9003 7.95166 9.48344 7.29691 10.1126 6.8263Z" fill="#E92E2F" stroke="#E92E2F" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M13.6319 12.7395C13.8468 12.4787 14.1895 12.4122 14.5015 12.3712C15.0437 12.2996 15.6218 12.4377 16.031 12.8112C16.3686 13.113 16.5578 13.5836 16.4862 14.0388C16.4095 14.5657 16.0566 15.0056 15.6576 15.3381C14.9363 15.9161 14.0462 16.2537 13.1562 16.4788C13.2073 16.4225 13.2636 16.3714 13.3301 16.3305C14.0053 15.9673 14.6754 15.5427 15.1716 14.9493C15.4734 14.581 15.7087 14.1002 15.6115 13.6143C15.5092 13.0516 14.9619 12.6424 14.4043 12.6117C14.1383 12.5861 13.8877 12.6884 13.6319 12.7395Z" fill="black" stroke="black" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M3.35028 13.2051C4.29149 12.801 5.31455 12.5401 6.34272 12.5554C5.74423 12.7293 5.1304 12.8777 4.56772 13.1437C4.43472 13.2153 4.28126 13.2818 4.20453 13.4199C4.16361 13.5273 4.27615 13.5989 4.35799 13.6347C4.70583 13.778 5.08947 13.7984 5.46289 13.8342C6.7724 13.9212 8.08703 13.87 9.39142 13.7882C10.6805 13.7064 11.9593 13.5324 13.233 13.3278C12.9517 13.4608 12.6652 13.5938 12.4043 13.7626C12.2713 13.8547 12.1076 13.8803 11.9593 13.9161C10.394 14.2639 8.78782 14.3713 7.19185 14.3969C6.57291 14.402 5.95907 14.3918 5.34524 14.3509C4.65979 14.3048 3.96923 14.2537 3.29913 14.0747C3.10475 14.0184 2.90013 13.957 2.74668 13.8189C2.66995 13.7575 2.66995 13.6347 2.73645 13.5631C2.90525 13.3892 3.14055 13.3074 3.35028 13.2051Z" fill="black" stroke="black" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M4.55743 15.3893C4.82342 15.1796 5.14057 15.0619 5.45772 14.9494C5.35541 15.0721 5.21219 15.1898 5.20196 15.3586C5.19684 15.466 5.2838 15.5479 5.37076 15.599C5.53956 15.686 5.72883 15.7167 5.9181 15.7422C6.90023 15.8445 7.89259 15.9008 8.88496 15.8343C9.79036 15.7883 10.6907 15.6399 11.5858 15.4814C11.8314 15.7115 12.1229 15.8701 12.4298 16.0082C11.3505 16.3254 10.2405 16.5095 9.12538 16.5965C8.07163 16.6681 7.00253 16.663 5.9539 16.4942C5.53956 16.4175 5.115 16.3305 4.73647 16.1361C4.58301 16.0543 4.40909 15.9469 4.36305 15.7678C4.33236 15.6144 4.44489 15.4762 4.55743 15.3893Z" fill="black" stroke="black" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M5.15079 17.6196C5.38609 17.4303 5.67766 17.3331 5.96412 17.2513C5.87204 17.3382 5.70836 17.4456 5.77997 17.594C5.91808 17.773 6.17385 17.7935 6.37846 17.8395C7.24294 17.9469 8.11765 18.0185 8.98213 17.952C9.63689 17.9111 10.2814 17.7935 10.9208 17.6809C11.2891 17.8958 11.6728 18.0799 12.0666 18.2436C11.463 18.5147 10.8134 18.6886 10.1638 18.8114C9.06909 19.0058 7.93862 19.0314 6.83883 18.8574C6.35288 18.7705 5.8567 18.658 5.41678 18.4175C5.24798 18.3203 5.06895 18.2078 4.99733 18.0185C4.94106 17.8702 5.03314 17.7116 5.15079 17.6196Z" fill="black" stroke="black" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M2.31183 19.0672C2.78244 18.9086 3.26839 18.7807 3.76457 18.7654C3.96407 18.7602 4.16868 18.7705 4.3426 18.8728C3.74923 18.9086 3.15585 19.0569 2.62387 19.328C2.50621 19.3945 2.37322 19.4713 2.32718 19.6094C2.32718 19.768 2.50621 19.8191 2.62387 19.8703C3.36558 20.1465 4.15845 20.2334 4.93597 20.3153C7.25319 20.5097 9.58576 20.4585 11.903 20.2488C12.7777 20.1516 13.6575 20.0544 14.5067 19.814C14.7778 19.7219 15.0847 19.6298 15.2637 19.3894C15.3711 19.2462 15.2995 19.0672 15.1921 18.9444C15.366 19.0416 15.6116 19.1644 15.5758 19.3997C15.5093 19.6452 15.2586 19.7731 15.054 19.8907C14.3992 20.2232 13.678 20.392 12.9618 20.5404C10.8799 20.9496 8.74685 21.0417 6.62913 20.9803C5.03316 20.924 3.43719 20.7706 1.86169 20.4994C1.58035 20.4329 1.2632 20.3767 1.05859 20.1567C0.925593 20.0033 1.04324 19.7884 1.17113 19.6759C1.49339 19.3792 1.90773 19.2155 2.31183 19.0672Z" fill="black" stroke="black" strokeWidth="0.09375" strokeMiterlimit="10" />
		<path d="M15.0591 20.8166C15.5757 20.6375 16.1282 20.4483 16.5118 20.0391C16.4453 20.4841 16.0617 20.791 15.6832 20.9905C14.9568 21.369 14.1435 21.543 13.3352 21.6862C11.6267 21.9573 9.89266 22.0238 8.15858 21.9931C6.97695 21.9573 5.78509 21.9113 4.62392 21.6657C4.3477 21.589 4.05101 21.5378 3.81059 21.3741C4.84388 21.5481 5.89251 21.6197 6.94114 21.6606C8.66499 21.7118 10.394 21.6453 12.1076 21.4304C13.0999 21.2923 14.0974 21.1235 15.0591 20.8166Z" fill="black" stroke="black" strokeWidth="0.09375" strokeMiterlimit="10" />
	</svg>
);

export default JavaIcon;