import React, { FC, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Table, Spin, Select, Input, Button, Space, Empty,
	Modal, notification } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Heading, Container } from "./styled";
import { CreateTaskExecutorForm } from "../../components/create-task-executor";
import { CustomInput } from "../../components/custom-input";
import { CustomTags } from "../../components/custom-tags";
import { SearchWorkspace } from "../../components/search-workspace";
import { logout } from "../../helpers/util";
import { DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import _ from "lodash";
import Item from "antd/lib/list/Item";

import "./index.css";

const GET_TASK_EXECUTERS = gql`
	query($workspaceId: String, $search: String,$after: String,$before: String) {
		getTaskExecutors(first:4,search: $search, workspaceId: $workspaceId,after: $after,before: $before) {
			pageInfo {
				prevPageCursor
		        nextPageCursor
			}
			nodes {
				id
				__typename
				... on TaskExecutor {
					taskExecutorId
					name
					description
					executorSysInfo {
						texPassword
					}
					workspace {
						id
					}
					workflows(first: 3) {
						
						nodes {
							id
							__typename
							... on Workflow {
								name
								id
							}
						}
					}
				}
			}
		}
	}
`;

const DELETE_TEX = gql`
	mutation deleteTaskExecutors(
		$ids: [String!]!
	) {
		deleteTaskExecutors(
			ids: $ids
		) {
			success
			errors {
				errorCode
				errorMessage
			}
		}
	}
`;

export const TaskExecutorsPage: FC<{}> = () => {
	const queryParams = new URLSearchParams(useLocation().search);
	const [afterPage, setAfterPage] = useState<any>(null)
  	const [beforePage, setBeforePage] = useState<any>(null)
	const [filter, setFilter] = useState<string>(
		queryParams.get("workspaceId") || ""
	);

	const navigate = useNavigate();

	const handleLogOut = () => {
		logout();
	};

	const [execSearchFilter, setExecSearchFilter] = useState<string>(
		queryParams.get("search") || ""
	);

	let loading;

	const {
		loading: loadingTask,
		error: errorTask,
		data: dataTask,
		refetch,
	} = useQuery(GET_TASK_EXECUTERS, {
		variables: { workspaceId: filter, search: execSearchFilter,after:afterPage,before:beforePage },
		fetchPolicy: "network-only",
	});


	const [delTex, {error:deleteError, data:deleteData,loading:deleteLoading}]  = useMutation(DELETE_TEX);

  	const deleteTex = (row:any) =>{
	
		Modal.confirm({
		title: 'Delete Task Executor?',
		content: "Delete this task executor including all workflows including run history? \n This action is not reversible.",
		okText:'Delete',
		onOk() {
			delTex({variables:{
			ids:[_.get(row,'id')]
			}}).then((res) => {
			
			if (_.get(res,'data.delTex.errors')==null) {
				notification["success"]({
				description:"Workflow deleted successfully",
				message: "Success",
				});
			
				refetch();
			}else if(_.get(res,'data.delTex.errors[0].errorMessage') ){
				notification.error({
					message: 'Error',
					description: _.get(res,'data.delTex.errors[0].errorMessage'),
					});
				}
			
			})
			.catch((err) => {
			notification["error"]({
				message: "An unknown error occured",
				description: err?.message,
			});
			});},
		cancelButtonProps: {
			disabled: false
		},
		// confirmLoading:true,
		cancelText: 'Cancel'
		})
  
  	}

	if (loadingTask || deleteLoading) {
		if (!execSearchFilter.length) {
			return (
				<div className="center-loader">
					<Spin size="large" />
				</div>
			);
		} else {
			loading = (
				<div className="center-loader">
					<Spin size="large" />
				</div>
			);
		}
	}
	if (errorTask)
		return (
			<p>
				Your session has expired, please{" "}
				<b onClick={handleLogOut}>login</b> again
			</p>
		);


		const onChangeAfterBeforePage=(type:any)=>{
			if(_.get(dataTask,'getTaskExecutors.pageInfo')) {
				
				if (type=='next' &&_.get(dataTask,'getTaskExecutors.pageInfo.nextPageCursor'))
					{
						setBeforePage(null)
						setAfterPage(_.get(dataTask,'getTaskExecutors.pageInfo.nextPageCursor'))
					}else if(type=='prev' && _.get(dataTask,'getTaskExecutors.pageInfo.prevPageCursor')){
						setAfterPage(null)
						setBeforePage(_.get(dataTask,'getTaskExecutors.pageInfo.prevPageCursor'))
					} 
			}
		};

	const allTaskExecuters = dataTask?.getTaskExecutors?.nodes?.map(
		(item: any) => {
			return {
				name: item.name,
				description: item.description,
				secret: item.executorSysInfo?.texPassword,
				taskExecutorId: item.taskExecutorId,
				workflows: item.workflows,
				workspaces: item.workspaces,
				id: item.id,
			};
		}
	);

	

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
		},
		{
			title: "Description",
			dataIndex: "description",
		},
		{
			title: "Authentication Secret",
			dataIndex: "secret",
			render: (text: any) => <CustomInput value={text} />,
		},
		{
			title: "Associated workflows",
			dataIndex: "workflows",
			render: (data: any) => <CustomTags data={data} />,
		},
		{
			title: "Action",
			dataIndex: "id",
			render: (data: string) => (
				<Link className="details-button" to={`/taskexecutors/${data}`}>
					View Details
				</Link>
			),
		},
		{
			title: "Delete",
			dataIndex: "id",
			render: (text:any, row:any) => (
				<Space size="middle">
					<div><DeleteOutlined onClick={()=>deleteTex(row)} /></div>
				</Space>
			)
		}
	];
	

	const dataforTable: any[] = [];

	for (let i = 0; i < allTaskExecuters?.length; i++) {
		dataforTable.push({
			key: i,
			name: allTaskExecuters[i].name,
			description: allTaskExecuters[i].description,
			secret: allTaskExecuters[i].secret,
			workflows: allTaskExecuters[i].workflows,
			id: allTaskExecuters[i].id,
		});
	}

	const handleChange = (value: any) => {
		setFilter(value);
		const params = new URLSearchParams();
		params.append("workspaceId", value);
		params.append("search", queryParams.get("search") || "");
		navigate({ search: params.toString() });
	};

	const onExecSearch = (e: any) => {
		setExecSearchFilter(e.target.value);
		const params = new URLSearchParams();
		params.append("search", e.target.value);
		params.append("workspaceId", queryParams.get("workspaceId") || "");
		navigate({ search: params.toString() });
	};

	const { Search } = Input;

	return (
		<>
			<Container>
				<Heading>Task Executors</Heading>
				<CreateTaskExecutorForm refetch={refetch} />
			</Container>
			<Container>
				<SearchWorkspace handleChange={handleChange} />
				{/* <Search
					placeholder="Search"
					className="default-search"
					onChange={onExecSearch}
				/> */}
			</Container>
			<br />
			<br />
			{loading}
			{
				!_.isEmpty(dataforTable) ?
				(
					<Table
						rowClassName={(record, index) =>
							index === 0
								? "table-head"
								: index % 2 === 0
								? "table-row-light"
								: "table-row-dark"
						}
						columns={columns}
						dataSource={dataforTable}
						pagination={false}
					/>
				):
				(<Empty/>)
			}
			<br />
			{ _.isArray(allTaskExecuters)==true && _.size(allTaskExecuters)>0 && (_.get(dataTask,'getTaskExecutors.pageInfo.prevPageCursor') || _.get(dataTask,'getTaskExecutors.pageInfo.nextPageCursor')) && <div className="row" style={{textAlign:'center'}}>
						<Button icon={<LeftOutlined style={{marginLeft:'3px'}} />}  disabled={_.get(dataTask,'getTaskExecutors.pageInfo.prevPageCursor')?false:true} style={{marginRight:"25px"}} onClick={()=>onChangeAfterBeforePage('prev')}>Previous</Button>
						<Button  disabled={_.get(dataTask,'getTaskExecutors.pageInfo.nextPageCursor')?false:true} onClick={()=>onChangeAfterBeforePage('next')}>
						Next <RightOutlined style={{marginLeft:'3px'}} /></Button>
					</div>}
		</>
	);
};