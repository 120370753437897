import { useState } from "react";
import { useAuth } from "../../controllers/auth-controller";
import { config } from "../../conf";
// @ts-ignore
const LOGIN_URL = config.api_url.concat("/login");

export const useSignInService = () => {
  const [isSignInInProgress, setSignInInProgress] = useState(false);
  const [signInResponse, setSignInResponse] = useState();
  const auth = useAuth();

  // @ts-ignore
  const signIn = async ({ emailAddress, password }, cb, errorCb) => {
    setSignInInProgress(true);

    function fetchApi() {
      return fetch(LOGIN_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({ emailAddress, password }),
      }).then(async (resp) => {
        if (resp.status < 200 || resp.status > 299) {
          throw resp.status;
        }

        return resp;
      });
    }

    let response;
    let message;

    try {
      response = await fetchApi();
    } catch (status) {
      message = status;
    }

    if (response) {
      const authHeader = response.headers.get("Authorization");

      // @ts-ignore
      auth.setAuthType('cesium-auth');
      // @ts-ignore
      auth.setSignedInToken(authHeader);

      const jsonResponse = await response.json();

      // @ts-ignore
      auth.setSignedInUser(jsonResponse);

      setSignInResponse(jsonResponse);
      setSignInInProgress(false);
      cb();
    }
    if (message) {
      setSignInInProgress(false);
      errorCb(message);
    }

    //TODO
    //const userData = response.get('')
  };

  return [isSignInInProgress, signInResponse, signIn];
};
