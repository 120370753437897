import { Dropdown, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { EmailNotification } from "./email-notification";
import { WebhookNotification } from "./webhook-notification";
import styled from "@emotion/styled";

export type EXEC_STATUS = "WFEXECSTATUS_STARTED" | "WFEXECSTATUS_FAILED" | "WFEXECSTATUS_SUCCESS";
type NOTIFICATION_TYPE = "EMAIL" | "WEBHOOK";
export type KEY_VALUE_PAIR = { name: string, value: string };

interface NotificationProps {
    workflowNotifications: any[];
    setWorkflowNotifications: (any)

}
export const WorkflowNotification: React.FC<NotificationProps> = ({ workflowNotifications, setWorkflowNotifications }) => {

    // Notifications Menu
    const NotificationsMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => showWorkflowNotificationInput("EMAIL")}>
                Add Email
            </Menu.Item>
            <Menu.Item key="2" onClick={() => showWorkflowNotificationInput("WEBHOOK")}>
                Add Webhook
            </Menu.Item>
        </Menu>
    );

    const showWorkflowNotificationInput = (type: NOTIFICATION_TYPE) => {
        const existingNotifications = workflowNotifications.filter(wn => wn.channelType === type)?.length;
        if (type === "EMAIL") {
            if (existingNotifications >= 3) {
                return; //TO DO : Show max exceeded message.
            }
            else {
                setWorkflowNotifications((prev: any) => {
                    return [...prev, { channelType: "EMAIL", execStatuses: [], emailWFNotification: { recipients: [] } }]
                });
            }
        } else if (type === "WEBHOOK") {
            if (existingNotifications >= 2) {
                return; //TO DO : Show max exceeded message.
            }
            else {
                setWorkflowNotifications((prev: any) => {
                    return [...prev, { channelType: "WEBHOOK", execStatuses: [], webhookNotification: { method: "POST", headers: [], uri: "" } }]
                });
            }
        }
    }

    const removeWorkflowNotification = (index: number) => {
        setWorkflowNotifications((wn: any) => {
            return wn.filter((_: any, i: number) => i !== index);
        });
    }

    const updateWorkflowNotification = (updatedWorkflow: any, index: number) => {
        setWorkflowNotifications((wn: any) => {
            const updated = wn.map((wh: any, i: number) => {
                if (i === index)
                    return updatedWorkflow
                else
                    return wh;
            })
            return updated;
        });
    }

    return (<>
        <Dropdown.Button onClick={() => showWorkflowNotificationInput("EMAIL")} overlay={NotificationsMenu} placement="bottomRight" icon={<CaretDownOutlined />}>
            Add Email
        </Dropdown.Button>
        <NotificationsContainer>

            {
                workflowNotifications && workflowNotifications.map((workflownotification: any, index: number) => (
                    workflownotification.channelType === "EMAIL" ?
                        <EmailNotification
                            key={index}
                            emailNotification={workflownotification}
                            removeEmailNotification={() => removeWorkflowNotification(index)}
                            updateEmailNotification={(updatedEmail) => updateWorkflowNotification(updatedEmail, index)} />
                    :
                        <WebhookNotification
                            key={index}
                            webhookNotification={workflownotification}
                            removeWebhookNotification={() => removeWorkflowNotification(index)}
                            updateWebhookNotification={(updatedWebhook) => updateWorkflowNotification(updatedWebhook, index)} />
                ))
            }
        </NotificationsContainer>
    </>)
}

export const NotificationsContainer = styled.div`
width: 95%;
display: flex;
flex-direction: column;
margin: 0 0 24px;
`;
