export function execStatusMap(status: any) {
    switch (status) {
        case "WFEXECSTATUS_REQUESTED":
            return "Requested";
        case "WFEXECSTATUS_STARTED":
            return "Started";
        case "WFEXECSTATUS_SUCCESS":
            return "Success";
        case "WFEXECSTATUS_UNSPECIFIED":
            return "Unspecified";
        case "WFEXECSTATUS_INVALID":
            return "Invalid";
        case "WFEXECSTATUS_INPROGRESS":
            return "Inprogress";
        case "WFEXECSTATUS_FAILED":
            return "Failed";
        case "WFEXECSTATUS_HALTED":
            return "Halted";
        case "WFEXECSTATUS_EXPIRED":
            return "Expired";
        default:
            return "Error";
    }
}
