import styled from "@emotion/styled";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
`;

export const Heading = styled.h1`
	font-size: 28px;
	font-weight: bold;
	text-transform: capitalize;
`;

export const SubHeading = styled.h2`
	font-size: 16px;
	color: #363746;
`;
export const MainLeft = styled.div`
	width: 30%;
`;

export const MainRight = styled.div`
	width: 67%;
`;

export const HeadingMainContainer = styled.p`
	color: #252ce1;
	font-size: 20px;
	font-weight: bold;
`;
