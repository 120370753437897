import React, { useState, FC } from "react";

// @ts-ignore
export const CustomTags = (props: any) => {
	return (
		<div className="tag-container">
			{props?.data?.nodes.map((item: any, idx: any) => (
				<span key={idx} className="tags">
					{item.name}
				</span>
			))}
		</div>
	);
};
