import styled from "@emotion/styled";

export const NotificationsCard = styled.div`
background : #777
width: 100%;
float: left;
border: 1px solid #EEF0F4;
padding: 15px 10px;
margin-top: 10px;
position: relative;
`;
