import styled from "@emotion/styled";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

export const Heading = styled.h1`
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 0px;
	flex-grow: 1;
`;
