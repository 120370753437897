import React, { useState, FC } from "react";

// @ts-ignore
export const CustomInput = (props: any) => {
	const [isHide, setHide] = useState(true);

	const setShowHide = () => {
		setHide(!isHide);
	};

	return (
		<>
			<input
				readOnly
				type={isHide ? "password" : "text"}
				value={props.value}
				className="secret-input"
			/>
			<p onClick={setShowHide} className="show-hide">
				{isHide ? "Show" : "Hide"}
			</p>
		</>
	);
};
