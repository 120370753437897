import styled from "@emotion/styled";

export const TopbarWrapper = styled.div`
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 1px 2px #d9dee8;
`;

export const ProfileWrapper = styled.div`
  margin: 0;
  padding-top: 0;
  padding-right: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100vw - 200px);
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;
