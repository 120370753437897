import React, { FC, useState } from "react";
import { ProfileWrapper, TopbarWrapper } from "./styled";
import { Logo } from "../../components/logo";

import { Avatar } from "antd";

import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useAuth } from "../../controllers/auth-controller";
import { UserProfile } from "../user-profile";
import { logout as cesium_logout } from "../../helpers/util";
import { useAuth0 } from "@auth0/auth0-react";
import "./index.css";


export const Topbar: FC<{}> = () => {
  let auth = useAuth();
  const { logout } = useAuth0();
  const [user, setUser] = useState(
    // @ts-ignore
    JSON.parse(localStorage.getItem("user"))
  );

  const profileToggle = () => {
    document.getElementById("toggleModal")?.click();
  };
  
  const handleLogout = () => {
    // @ts-ignore
    if (auth?.authType === 'auth0') {
      logout();
    } 
    cesium_logout();
  };
  
  const menu = (
    <Menu>
      <Menu.Item onClick={profileToggle}>View Profile</Menu.Item>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <>
      <UserProfile user={user} />
      <TopbarWrapper className="app_top_bar_wrapper">
        <Logo />
        <ProfileWrapper>
          <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link black"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar size={30}>U</Avatar>
              <span className="name-margin">{user?.name}</span>

              <DownOutlined />
            </a>
          </Dropdown>
        </ProfileWrapper>
      </TopbarWrapper>
    </>
  );
};
