
import { Col, Collapse, Input, Row, Select } from 'antd';
import { InfoCircleOutlined} from '@ant-design/icons';
import DeleteButton from './delete-button';

const AWSEMRSparkRunTask = (props:any) => {
    const { item, handleChange, handleDependsOnSelectChange, deleteTask} = props;
    const { Panel } = Collapse;
    const { Option } = Select;
    const handleMultiChange = (value:string | string[], field : string) => {
        handleChange(value , field);
    }
    console.log('inside the run docker task component, item:' + item)
    return (
        <Panel
            {...props}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>Name</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="AWS EMR Spark task name"  value={item.name} onChange={(ev:any) => handleChange(ev.target.value,'name')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>AWS Access Key</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="AKIA31234ABCDEF1234"  value={item.awsAccessKey} onChange={(ev:any) => handleChange(ev.target.value,'awsAccessKey')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>AWS Secret Key</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="aws secret key"  value={item.awsSecretKey} onChange={(ev:any) => handleChange(ev.target.value,'awsSecretKey')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>AWS Region</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="aws secret key"  value={item.awsRegion} onChange={(ev:any) => handleChange(ev.target.value,'awsRegion')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>EMR Cluster Id</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="J-clusterid"  value={item.clusterId} onChange={(ev:any) => handleChange(ev.target.value,'clusterId')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>Spark Main Class</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="Class Name"  value={item.className} onChange={(ev:any) => handleChange(ev.target.value,'className')} style={{marginTop: '0'}} />
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className="gutter-row" span={6}>
                        <label style={{marginRight: '6px'}}>Jar Path</label>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input placeholder="S3 or Local Jar Path"  value={item.jarPath} onChange={(ev:any) => handleChange(ev.target.value,'jarPath')} style={{marginTop: '0'}} />
                    </Col>
                </Row>

                <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                    <Col className='gutter-row' span={6}>
                        <span style={{marginRight: '6px'}}>Arguments</span>
                        <InfoCircleOutlined />
                    </Col>
                    <Col className='gutter-row' span={10}>
                    <Select mode="tags"
                        placeholder="Please enter multiple arguments"
                        style={{ width: '100%' }}
                        defaultValue={item.args}
                        onChange={(ev:any) => handleMultiChange(ev,'args')}
                    />
                    </Col>
                </Row>
                {(props.definitionData.filter((data:any) => data.id !== item.id && data.name.length > 0)).length > 0 && (
                    <Row gutter={16} style={{marginTop:'20px'}} align="middle">
                        <Col className='gutter-row' span={6}>
                            <span style={{marginRight: '6px'}}>Depends On</span>
                            <InfoCircleOutlined />
                        </Col>
                        <Col className='gutter-row' span={10}>
                            <Select
                                mode="multiple"
                                placeholder="Please select multiple"
                                onChange={handleDependsOnSelectChange}
                                value={item.dependsOn}
                                style={{ width: '100%' }}  >
                                {props.definitionData.filter((data:any) => data.id !== item.id).map((value:any) => <Option key={value.id}>{value.name}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                )}
        <br/>
        <br/>
        <DeleteButton deleteTask={deleteTask} />
      </Panel>
    )
}

export default AWSEMRSparkRunTask;
